import type { LastUsedApplication, TenantId } from '~/composables/domain/useTenantConfig/types'

export interface PersonalData {
  name: string
  birthday: string
  avatarContent: string
}

export interface Contract {
  name: string
  id: string
}

export interface ContactEmailAddress {
  id: string
  value: string
}

export interface ContractEmailAddress {
  id: string
  value: string
  contracts: Contract[]
}

export interface ContactPhoneNumber {
  id: string
  value: string
}

export interface ContractPhoneNumber {
  id: string
  value: string
  contracts: Contract[]
}

export interface CommunicationChannelData {
  emailCount: number
  contactEmailAddresses: ContactEmailAddress[]
  contractEmailAddresses: ContractEmailAddress[]
  phoneNumberCount: number
  contactPhoneNumbers: ContactPhoneNumber[]
  contractPhoneNumbers: ContractPhoneNumber[]
}

interface Iban {
  value: string
  encryptedValue: string
}

export interface ContractBankAccount {
  id: string
  relatedIds: string[]
  accountHolder: string
  iban: Iban
  bic: string
  bankName: string
  directDebitAuthorization: boolean
  contracts: Contract[]
}

export interface SelfPayment {
  contracts: Contract[]
}

export interface PaymentData {
  contractBankAccounts: ContractBankAccount[],
  selfPayments: SelfPayment[],
  selfPaymentsWithBankAccount: ContractBankAccount[]
}

export interface RegistrationData {
  email: string
  lastPasswordResetDate: Date | undefined | null
  lastUsedApplication?: LastUsedApplication
}

export interface Address {
  id: string
  relatedIds: string[]
  firstname: string
  surname: string
  houseNumber: string
  street: string
  addressType: number
  city: string
  companyName: string
  zipCode: string
  contracts?: Contract[]
}

export interface AddressData {
  billingAddresses: Address[]
  contactAddresses: Address[]
  deliveryAddresses: Address[]
}

export interface ContractDataItem {
  contractId: string,
  contractSystem: string,
  productName: string,
  businessArea: string,
  tenant: number,
  contractStatus : string | undefined | null,
  isPaperlessCommunication: boolean
}

export interface ContractData {
  contracts: ContractDataItem[]
}

export interface CustomerProfileData {
  personalData: PersonalData,
  communicationChannelData: CommunicationChannelData,
  contractData: ContractData,
  paymentData: PaymentData,
  addressData: AddressData
}

export enum TenantMode {
  MEK = 'mEK',
  SELECTED = 'selected'
}

interface State {
  tenantMode: TenantMode,
  selectedTenantId?: TenantId,
  registrationData?: RegistrationData,
  _customerProfileData?: CustomerProfileData,
  isCustomerProfileDataLoading: boolean
}

export default State
