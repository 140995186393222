import { Persona } from '~/middleware/utils/useFetch/types'
import useAppConfigStore from '~/stores/app-config'

export interface DeployConfig {
  APPLICATIONINSIGHTS_CONNECTION_STRING: string
  APIM_URL: string
  BFF_API: string
  AUTH0_DOMAIN: string
  AUTH0_CLIENT_ID: string
  AUTH0_AUDIENCE: string
  AUTH0_SCOPE: string
  BFF_SUBSCRIPTION_KEY: string
  IS_AUTH0_MOCKED: string
  IS_CMDM_MOCKED: string
  MOCK_DATA_PERSONA: string,
  YELLO_PORTAL_URL: string,
  ENBW_PORTAL_URL: string,
  REFERRING_TOUCHPOINTS: string,
  GTM_ID: string,
  GTM_ENV: string,
  ONE_TRUST_COOKIE_DOMAIN: string
}

const useAppConfig = () => {
  const appConfigStore = useAppConfigStore()
  const appConfig = appConfigStore.appConfig

  return reactive({
    ...appConfig,
    IS_AUTH0_MOCKED: appConfig?.IS_AUTH0_MOCKED === 'true',
    IS_CMDM_MOCKED: appConfig?.IS_CMDM_MOCKED === 'true',
    MOCK_DATA_PERSONA: appConfig?.MOCK_DATA_PERSONA as Persona,
    REFERRING_TOUCHPOINTS : appConfig?.REFERRING_TOUCHPOINTS?.split(',')
  })
}

export default useAppConfig
