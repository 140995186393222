<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { EDialogContentProps } from '~/components/design-system/EDialog/types'
import TestIds from '~cypress/types/testIds'
import { TRACKING_EVENTS } from '~/utils/constants/eventNames'
import useAppConfig from '~/composables/useAppConfig'

defineProps({
  mobile: { type: Boolean },
  maximized: { type: Boolean }
})

const { t } = useI18n()

const { logout } = useAuth0()
const isLoading = ref(false)
const appConfig = useAppConfig()

const appInsights = inject('appInsights') as ApplicationInsights

function handleLogoutClick () {
  appInsights.trackEvent({ name: TRACKING_EVENTS.LOGOUT })

  isLoading.value = true
  logout({ logoutParams: { returnTo: window.location.origin } })
}

function navigateToUrl(url: string) {
  window.location.href = url
}

defineExpose({
  t,
  TestIds,

  isLoading,
  handleLogoutClick
})
</script>

<template>
  <div class="senec-tenant-unavailable-dialog full-height cdc-mb-56">
    <q-card-section class="senec-tenant-unavailable-dialog__body">
      <component
        :is="mobile ? 'h3' : 'h2'"
        class="font-mark cdc-fw-500"
        :class="{ 't3': mobile, 't2': !mobile }"
      >
        {{ t('dialogs.senec-tenant-unavailable.title') }}
      </component>

      <img
        class="svgImage"
        src="./image.svg"
        alt="SENEC"
      >

      <br><br>

      <q-item-section>
        <q-item-label class="cdc-fw-500 full-width column justify-center">
          {{ t('dialogs.senec-tenant-unavailable.content') }}
        </q-item-label>
      </q-item-section>

      <br>

      <EButton
        :data-testId="TestIds.TENANT_SENEC_UNAVAILABLE_OPEN_PORTAL"
        color="primary-blue"
        :text="t('dialogs.senec-tenant-unavailable.openPortal')"
        :is-loading="isLoading"
        full-width
        class="text-primary"
        @click="navigateToUrl(appConfig.SENEC_PORTAL_URL)"
      />

      <br><br>

      <TextButton
        :data-testId="TestIds.TENANT_SENEC_UNAVAILABLE_LOGOUT"
        :button-text="t('dialogs.logout.button')"
        :on-button-click="handleLogoutClick"
        class="full-width text-secondary"
      />
    </q-card-section>
  </div>
</template>

<style lang="scss" scoped>
@import '~vars';

.senec-tenant-unavailable-dialog {
  display: flex;
  flex-direction: column;

  .senec-tenant-unavailable-dialog__body {
    flex: 1 0 auto;
  }
}

img.svgImage {
  display: block;
  margin: auto;
}

</style>
