{
  "common": {
    "all": "Alle",
    "view-all": "Alle anzeigen",
    "not-specified": "Nicht angegeben",
    "tenant": {
      "enbw": {
        "title": "EnBW"
      },
      "yello": {
        "title": "Yello"
      }
    }
  },

  "layout": {
    "nav-tabs": {
      "overview": "Übersicht",
      "personal-data": "Persönliche Daten",
      "registration-security": "Anmeldung & Sicherheit",
      "payment-contract": "Zahlung & Verträge",
      "data-privacy": "Privatsphäre"
    },
    "buttons": {
      "back": "Zurück",
      "abort": "Abbrechen"
    },
    "footer": {
      "impressum": "Impressum",
      "privacy": "Datenschutz",
      "about-mek": "Über den myEnergyKey"
    },
    "not-found": {
      "title": "Oh nein!",
      "text": "Die von Ihnen gesuchte Seite kann nicht gefunden werden. Bitte prüfen Sie die URL.",
      "button": "Zurück zur Startseite"
    },
    "toast": {
      "touchpoint-link": "Zurück zu {name}"
    }
  },

  "dialogs": {
    "common": {
      "permissions": {
        "channels": {
          "phone": { "title": "Telefon" },
          "email": { "title": "E-Mail" },
          "sms": { "title": "SMS" }
        },
        "toggleLabelActivated": "Zustimmung erteilt",
        "toggleLabelDeactivated": "Keine Zustimmung",
        "contactMissingLabel": "Nicht angegeben",
        "product-consent": {
          "title": "Gesetzliche Erlaubnis",
          "enbw": {
            "text": "Aufgrund einer gesetzlichen Erlaubnisnorm darf die EnBW Energie Baden-Württemberg AG GmbH mich per Brief über eigene Angebote informieren. Der Verarbeitung meiner Daten zu Themen der Werbung bzw. der Marktforschung kann ich jederzeit widersprechen."
          },
          "yello": {
            "text": "Aufgrund einer gesetzlichen Erlaubnisnorm darf die Yello Strom GmbH mich per Brief über eigene Angebote informieren. Der Verarbeitung meiner Daten zu Themen der Werbung bzw. der Marktforschung kann ich jederzeit widersprechen."
          },
          "action": "Widersprechen"
        },
        "googleCustomerMatch": {
          "title": "Personalisierte Google Werbung",
          "enbw": {
            "text": "Um mir personalisierte Angebote in der Google Suche, auf dem Shopping-Tab, in Gmail, auf YouTube und im Displaynetzwerk anzeigen zu können, verwendet die EnBW den {moreCta}."
          },
          "yello": {
            "text": "Um mir personalisierte Angebote in der Google Suche, auf dem Shopping-Tab, in Gmail, auf YouTube und im Displaynetzwerk anzeigen zu können, verwendet Yello den {moreCta}."
          },
          "textMoreCta": "Google Kundenabgleich"
        },
        "footer": {
          "save": "Speichern"
        },
        "topicsLabel": "Thema | Themen",
        "channelsLabel": "Kanal | Kanäle",
        "specialTopicsLabel": "Zustimmung | Zustimmungen"
      },
      "footer": {
        "save": "Speichern",
        "close": "Schließen",
        "continue": "Weiter"
      }
    },
    "logout": {
      "title": "Ihr myEnergyKey-Konto",
      "button": "Abmelden"
    },
    "helpful-info": {
      "email-title": "E-Mail-Adresse ändern",
      "email-content": "Leider ist das Ändern dieser E-Mail-Adresse über diese Oberfläche aktuell noch nicht möglich. Bitte kontaktieren Sie den Support um Ihre E-Mail-Adresse ändern zu lassen.",
      "phone-title": "Telefonnummer ändern",
      "phone-content": "Leider ist das Ändern dieser Telefonnummer über diese Oberfläche aktuell noch nicht möglich. Bitte kontaktieren Sie den Support um Ihre Telefonnummer ändern zu lassen.",
      "request-relocation-title": "Umzug beantragen",
      "request-relocation-content": "Leider ist das umziehen mehrer Verträge über diese Oberfläche aktuell noch nicht möglich. Bitte kontaktieren Sie den Support um Ihre Verträge umzuziehen.",
      "change-contracts-address-title": "Adresse ändern",
      "change-contracts-address-content": "Leider ist das Ändern der Adresse für mehrere Verträge über diese Oberfläche aktuell noch nicht möglich. Bitte kontaktieren Sie den Support um Ihre Adresse ändern zu lassen.",
      "change-contact-address-title": "Adresse ändern",
      "change-contact-address-content": "Leider ist das Ändern der Kontakt-Adresse  über diese Oberfläche aktuell noch nicht möglich. Bitte kontaktieren Sie den Support um Ihre Adresse ändern zu lassen.",
      "change-bank-account-title": "Bankverbindung ändern",
      "change-bank-account-content": "Leider ist das Ändern der Bankverbindung über diese Oberfläche aktuell noch nicht möglich. Bitte kontaktieren Sie den Support um Ihre Bankverbindung ändern zu lassen.",
      "pin-text-1": "Ggf. benötigen Sie Ihren",
      "pin-text-2": "Telefon-PIN",
      "schedule-1": "Montag-Freitag: 08:00-18:00 Uhr",
      "schedule-2": "Samstag: 08:00-14:00 Uhr",
      "close-button": "Schließen"
    },
    "edit-birthdate": {
      "headline": "Ihr Geburtsdatum",
      "description": "Das Geburtsdatum kann bei Serviceanfragen zur Identifikation abgefragt werden.",
      "action": "Speichern",
      "placeholder": "TT.MM.JJJJ",
      "validation-messages": {
        "required": "Bitte geben Sie ein Geburtsdatum ein",
        "invalid": "Bitte geben Sie ein gültiges Geburtsdatum an.",
        "must-be-in-past": "Das Geburtsdatum muss in der Vergangenheit liegen",
        "must-not-be-far-past": "Darf nicht vor mehr als 125 Jahren geboren sein."
      },
      "toast": {
        "error": {
          "title": "Änderung fehlgeschlagen",
          "content": "Versuchen Sie es später erneut"
        },
        "success": {
          "title": "Geburtsdatum geändert."
        }
      }
    },
    "edit-email": {
      "select-email-title": "Eine bereits im Konto hinterlegte E-Mail verwenden?",
      "contracts-title": "Für welche Verträge soll die E-Mail geändert werden?",
      "new-email-link": "Neue E-Mail eingeben",
      "advertising-contact": "Für Werbung genutzt",
      "button": {
        "use-email": "Gewählte E-Mail verwenden"
      },
      "toast": {
        "error": {
          "title": "Anfrage fehlgeschlagen",
          "content": "Versuchen Sie es später nocheinmal"
        },
        "partial-error": {
          "title": "Änderung teilweise fehlgeschlagen",
          "content": "E-Mail konnte nicht für alle Verträge geändert werden."
        },
        "success": {
          "title": "E-Mail-Adresse erfolgreich geändert."
        }
      }
    },
    "email-sent": {
      "headline": "E-Mail-Adresse bestätigen",
      "description": "Um Ihre E-Mail-Adresse zu bestätigen, klicken Sie bitte auf den Link in der E-Mail, die wir Ihnen an {email} zugeschickt haben.",
      "button": {
        "submit-again": "Neue Bestätigung anfordern",
        "change-email": "E-Mai-Adresse ändern"
      }
    },
    "email-form": {
      "headline": "Neue E-Mail-Adresse",
      "placeholder": "E-Mail-Adresse",
      "action": "Neue Einladung anfordern",
      "validation-messages": {
        "required-email": "Bitte geben Sie eine E-Mail-Adresse an.",
        "invalid-email": "Geben Sie eine gültige E-Mail-Adresse an."
      },
      "already-existing-email-error": "E-Mail-Adresse wird bereits verwendet. Geben Sie eine neue Adresse an."
    },
    "edit-phone": {
      "phones-title": "Eine bereits im Konto hinterlegte Nummer verwenden?",
      "contracts-title": "Für welche Verträge soll die Telefonnummer geändert werden?",
      "new-phone-link": "Neue Nummer eingeben",
      "advertising-phone": "Für Werbung genutzt",
      "button": {
        "use-number": "Gewählte Nummer verwenden"
      }
    },
    "edit-personal-item": {
      "button": {
        "change-all": "Für alle ändern",
        "change-nothing": "Nichts ändern",
        "continue": "Weiter"
      }
    },
    "bulk-edit-contact-phone": {
      "one-phone-title": "Neue Nummer auch zu Werbezwecken nutzen?",
      "multiple-phones-title": "Alte Kontakt-Nummern auch durch die neue Nummer ersetzen?",
      "hint": "Aktuell dafür genutzte Nummer",
      "error": "Wählen Sie mindestens einen Nummer aus",
      "button": {
        "use-number": "Aktuelle Nummer ersetzen",
        "use-numbers": "Nummern ersetzen",
        "change-all": "Alle Nummern ersetzen",
        "skip": "Überspringen"
      }
    },
    "edit-phone-pin": {
      "title": "Neuer Telefon-Pin",
      "input": {
        "hint": "Ihr Pin muss aus 5 Zahlen bestehen."
      },
      "footer": {
        "save": "Speichern"
      },
      "validation-messages": {
        "length-error": "Bitte geben Sie eine 5-stellige Zahl an."
      },
      "toast": {
        "success": { "headline": "Telefon-Pin geändert" },
        "error": { "headline": "Änderung fehlgeschlagen", "text": "Versuchen Sie es später nocheinmal" }
      }
    },
    "profile-deletion": {
      "title": "Bestätigung der Löschung",
      "text": "Bitte bestätigen Sie, dass Sie diesen myEnergyKey {email} und damit den Zugang zu all Ihren Energiediensten der EnBW endgültig löschen möchte."
    },
    "select-tenant": {
      "title": "Wählen Sie den Energiedienst aus, den Sie verwalten möchten:"
    },
    "senec-tenant-unavailable": {
      "title": "Service noch nicht verfügbar",
      "content": "Dieser Service ist aktuell für die SENEC noch nicht verfügbar. Wir arbeiten daran die SENEC sowie weitere Marken anzuschließen. Um Ihre Daten zu verwalten nutzen Sie bitte das SENEC-Kund:innen- oder Fachpartner-Portal.",
      "openPortal": "Kund:innen Portal öffnen"
    },
    "eco-permissions": {
      "title": "Werbeeinstellungen",
      "offersAndServicesText": "Angebots- und Serviceprogramm",
      "partnerText": "Partner | Partnern",
      "interestProfileText": "Interessenprofils",
      "footerText": "Ich kann jederzeit mit Wirkung für die Zukunft meine Einstellungen ändern und damit meine Einwilligung erteilen oder {revokeCta}, ohne dass die Rechtmäßigkeit der bis zum {revocationCta} erfolgten Datenverarbeitung berührt wird.",
      "revokeText": "widerrufen",
      "revocationText": "Widerruf",
      "infoBox": {
        "enbw": { 
          "text": "Gemäß einer gesetzlichen Erlaubnis darf die EnBW Ihnen bis zu ihrem Widerruf Informationen über eigene ähnliche Produkte und Dienstleistungen via SMS und E-Mail schicken."
        },
        "yello": { 
          "text": "Gemäß einer gesetzlichen Erlaubnis darf Yello mir bis zu meinem Widerruf Informationen über eigene ähnliche Produkte und Dienstleistungen via SMS und E-Mail schicken."
        }
      },
      "offerInformationDialog": {
        "title": "Angebots- und Serviceprogramm der {tenant} und ihrer Partner",
        "text": "Das vielfältige Angebots- und Serviceprogramm kann intelligent miteinander kombiniert werden. Es umfasst die folgenden Bereiche:",
        "list": {
          "energy": "Energie",
          "electricity": "Strom",
          "gas": "Gas",
          "heatSupplyAndHeating": "Wärmelieferung und -erzeugung",
          "energyRelatedServices": "energienahe Dienstleistungen",
          "energyStorage": "Energiespeicher",
          "meterOperation": "Messstellenbetrieb",
          "participationModelEnergyProduction": "Beteiligungsmodelle (an Energieerzeugungsanlagen)",
          "solarSystems": "Solaranlagen",
          "emobilityAndGreenhouse": "Elektromobilität und Treibhausgasminderungsquote",
          "telecommunications": "Telekommunikation",
          "fixedNetworkAndInternet": "Festnetz-, Internet-Mobilfunktarife",
          "streaming": "Streaming (Musik, Film)",
          "miscellaneous": "Sonstiges",
          "electricalAppliances": "Elektroklein- und -großgeräte inklusive Zubehör",
          "insurance": "Versicherungen (Haushalt, Hardwaresicherheit)",
          "customerBenefitPrograms": "Kundenvorteilsprogramme"
        }
      },
      "revocationInfoDialog": {
        "title": "Widerruf",
        "postalAddressLabel": "Postalisch",
        "telephoneLabel": "Telefonisch",
        "emailLabel": "Via E-Mail",
        "yello": {
          "text": "Bitte adressiere deinen Widerruf an:",
          "postalAddress": "Yello Strom GmbH,{0}Siegburger Straße 229, 50679 Köln",
          "email": "immerda{'@'}yello.de"
        },
        "enbw": {
          "text": "Bitte adressieren Sie Ihren Widerruf an:",
          "postalAddress": "EnBW Energie Baden-Württemberg AG,{0}Durlacher Allee 93, 76131 Karlsruhe",
          "email": "werbeeinwilligung{'@'}enbw.com"
        }
      },
      "interestProfileDialog": {
        "title": "Interessenprofil",
        "enbw": {
          "text": "Unter einem Interessenprofil verstehen wir eine Datenbasis, die sich aus den unten genannten Daten zusammensetzt. Wir nutzen das Interessenprofil, um Ihnen maßgeschneiderte Angebote zu unterbreiten. Um zu ermitteln, welche unserer Informationen, Produkte und Angebote zu Ihnen passen und Ihren Interessen entsprechen, werten wir Ihre Daten mit verschiedenen Algorithmen und statistischen Verfahren aus.",
          "text2": "Dabei beziehen wir zum Beispiel Daten zur Marktentwicklung mit ein und berücksichtigen, welchen Bedarf an Produkten und Energiedienstleistungen typischerweise mit Ihnen vergleichbare Kundengruppen haben. Wir verwenden die Daten aus dem Interessenprofil auch zum Entwickeln neuer Verfahren und Analysemodelle. Soweit möglich, verarbeiten wir Ihre Daten pseudonymisiert bzw. anonymisiert.",
          "text3": "Wir verarbeiten persönliche Daten, die wir aus Geschäftsbeziehungen und aus der Zusammenarbeit mit Ihnen gewonnen haben. Für noch passendere Angebote wird Ihr Interessenprofil mit Ihren persönlichen Daten bei den beteiligten Partnern angereichert. Zu den verarbeiteten Daten gehören:",
          "dataProcessList": {
            "storedData": {
              "title": "Daten, die zu Ihrer Person gespeichert sind.",
              "text": "Hierunter fallen:",
              "list": {
                "contactAndContract": {
                  "title": "Kontakt- und vertragsbezogene Daten.",
                  "text": "Hierunter verstehen wir Stamm- und Kontaktdaten wie z.B. Name, Anschrift, Geburtsdatum, Geschlecht, Familienstand, Personenbeziehungen, Ihre E-Mailadresse oder Telefonnummer."
                },
                "communication": {
                  "title": "Daten, die wir im Rahmen Ihrer Kommunikation mit uns erheben.",
                  "text": "Das sind Daten, die wir im Rahmen unserer Beratungs- und Serviceangebote online oder im direkten Gespräch gewonnen haben, wie zum Beispiel Ihre mitgeteilten Ziele und Wünsche, Ihre Produktkenntnisse und Erfahrungen sowie Angaben zu Ihren persönlichen Verhältnissen.",
                  "text2": "Dazu gehören auch beispielsweise Informationen, wie Ihre Stromverbrauchsdaten, Hinweise auf einen bevorstehenden Umzug oder sonstige wohnliche Veränderungen zur Ermittlung eines optimalen Tarifs, Informationen zur Planung einer Solaranlage, Informationen zu bestehenden oder geplanten Anschaffungen wie z. B. eines E-Autos, einer Wallbox oder einer Wärmepumpe."
                },
                "customerRelationship": {
                  "title": "Daten aus der Kundenbeziehung.",
                  "text": "Hierbei handelt es sich um Daten, die wir im Rahmen der Kundenbeziehung selbst erstellt oder aus anderen Quellen (z.B. aus öffentlich zugänglichen Verzeichnissen) zulässigerweise erhalten haben. Zu den Daten aus der Kundenbeziehung zählen z.B. Angaben aus Beratungsdokumentationen, wie etwa interne Protokolle, Kundenausdrucke sowie Daten aus der Konsumforschung und Informationen zur Wohnsituation oder -umfeld oder Informationen, die wir durch eigene Bewertungsverfahren gewinnen, um beispielsweise Ihre Zufriedenheit einzuschätzen."
                }
              }
            },
            "onlineUseData": {
              "title": "Daten aus Ihrer Onlinenutzung.",
              "text": "Das sind Daten, die wir bei Ihrer Nutzung unseres Online-Angebots (Webseiten, Apps oder Portale) verarbeiten. Hierzu zählen z.B. Informationen über den von Ihnen gewählten Zugangsweg/ Kommunikationskanal (wie etwa IP-Adresse oder die Art des Empfangsgeräts), Datum und Uhrzeit der Nutzung, Informationen zu Ihrer Servicehistorie sowie Informationen zu den von Ihnen aufgerufenen und genutzten Online-Angeboten."
            },
            "partnersData": {
              "title": "Daten unserer Partner.",
              "text": "Dazu zählen Daten, die uns zulässigerweise von unseren Partnern übermittelt worden sind. Dazu können z.B. Informationen zu Ihren dortigen Verträgen, aber auch Angaben zu Beratungswünschen sowie zu Ihren persönlichen Verhältnissen gehören."
            }
          }
        },
        "yello": {
          "text": "Unter einem Interessenprofil verstehen wir eine Datenbasis, die sich aus den unten genannten Daten zusammensetzt. Wir nutzen das Interessenprofil, um Dir maßgeschneiderte Angebote zu unterbreiten. Um zu ermitteln, welche unserer Informationen, Produkte und Angebote zu dir passen und deinen Interessen entsprechen, werten wir deine Daten mit verschiedenen Algorithmen und statistischen Verfahren aus.",
          "text2": "Dabei beziehen wir zum Beispiel Daten zur Marktentwicklung mit ein und berücksichtigen, welchen Bedarf an Produkten und Energiedienstleistungen typischerweise mit dir vergleichbare Kundengruppen haben. Wir verwenden die Daten aus dem Interessenprofil auch zum Entwickeln neuer Verfahren und Analysemodelle. Soweit möglich, verarbeiten wir deine Daten pseudonymisiert bzw. anonymisiert.",
          "text3": "Wir verarbeiten persönliche Daten, die wir aus Geschäftsbeziehungen und aus der Zusammenarbeit mit dir gewonnen haben. Für noch passendere Angebote wird dein Interessenprofil mit deinen persönlichen Daten bei den beteiligten Partnern angereichert. Zu den verarbeiteten Daten gehören:",
          "dataProcessList": {
            "storedData": {
              "title": "Daten, die zu deiner Person gespeichert sind.",
              "text": "Hierunter fallen:",
              "list": {
                "contactAndContract": {
                  "title": "Kontakt- und vertragsbezogene Daten.",
                  "text": "Hierunter verstehen wir Stamm- und Kontaktdaten wie z.B. Name, Anschrift, Geburtsdatum, Geschlecht, Familienstand, Personenbeziehungen, deine E-Mailadresse oder Telefonnummer."
                },
                "communication": {
                  "title": "Daten, die wir im Rahmen deiner Kommunikation mit uns erheben.",
                  "text": "Das sind Daten, die wir im Rahmen unserer Beratungs- und Serviceangebote online oder im direkten Gespräch gewonnen haben, wie zum Beispiel deine mitgeteilten Ziele und Wünsche, deine Produktkenntnisse und Erfahrungen sowie Angaben zu deinen persönlichen Verhältnissen.",
                  "text2": "Dazu gehören auch beispielsweise Informationen, wie deine Stromverbrauchsdaten, Hinweise auf einen bevorstehenden Umzug oder sonstige wohnliche Veränderungen zur Ermittlung eines optimalen Tarifs, Informationen zur Planung einer Solaranlage, Informationen zu bestehenden oder geplanten Anschaffungen wie z.B. eines E-Autos, einer Wallbox oder einer Wärmepumpe."
                },
                "customerRelationship": {
                  "title": "Daten aus der Kundenbeziehung.",
                  "text": "Hierbei handelt es sich um Daten, die wir im Rahmen der Kundenbeziehung selbst erstellt oder aus anderen Quellen (z.B. aus öffentlich zugänglichen Verzeichnissen) zulässigerweise erhalten haben. Zu den Daten aus der Kundenbeziehung zählen z.B. Angaben aus Beratungsdokumentationen, wie etwa interne Protokolle, Kundenausdrucke sowie Daten aus der Konsumforschung und Informationen zur Wohnsituation oder -umfeld oder Informationen, die wir durch eigene Bewertungsverfahren gewinnen, um beispielsweise deine Zufriedenheit einzuschätzen."
                }
              }
            },
            "onlineUseData": {
              "title": "Daten aus deiner Onlinenutzung.",
              "text": "Das sind Daten, die wir bei deiner Nutzung unseres Online-Angebots (Webseiten, Apps oder Portale) verarbeiten. Hierzu zählen z.B. Informationen über den von dir gewählten Zugangsweg/ Kommunikationskanal (wie etwa IP-Adresse oder die Art des Empfangsgeräts), Datum und Uhrzeit der Nutzung, Informationen zu deiner Servicehistorie sowie Informationen zu den von dir aufgerufenen und genutzten Online-Angeboten."
            },
            "partnersData": {
              "title": "Daten unserer Partner.",
              "text": "Dazu zählen Daten, die uns zulässigerweise von unseren Partnern übermittelt worden sind. Dazu können z.B. Informationen zu deinen dortigen Verträgen, aber auch Angaben zu Beratungswünschen sowie zu deinen persönlichen Verhältnissen gehören."
            }
          }
        }
      },
      "partnerInformationDialog": {
        "title": "Partner",
        "enbw": {
          "text": "Folgende Tochterunternehmen innerhalb der EnBW Energie Baden-Württemberg AG, Durlacher Allee 93, 76131 Karlsruhe (EnBW) entwickeln das vielfältige Angebots- und Serviceprogramm:"
        },
        "yello": {
          "text": "Folgende Unternehmen entwickeln das vielfältige Angebots- und Serviceprogramm:",
          "footerText": "Bei der SENEC GmbH, der DZ-4 GmbH und der EnBW mobility+ AG & Co. KG handelt es sich um Schwestergesellschaften der Yello Strom GmbH."
        },
        "partnerList": {
          "emobility": {
            "title": "EnBW mobility+ AG & Co. KG",
            "address": "Durlacher Allee 93, 76131 Karlsruhe (Anbieter für Elektromobilität)"
          },
          "yello": {
            "title": "Yello Strom GmbH",
            "address": "Siegburger Straße 229, 50679 Köln (Energieanbieter)"
          },
          "senec": {
            "title": "SENEC GmbH",
            "address": "Wittenberger Straße 15, 04129 Leipzig (Anbieter für Solaranlagen und Speicher)"
          },
          "dz4": {
            "title": "DZ-4 GmbH",
            "address": "Kühnehöfe 3, 22761 Hamburg (Anbieter für Solaranlagen)"
          },
          "enbw": {
            "title": "EnBW Energie Baden-Württemberg AG",
            "address": "Durlacher Allee 93, 76131 Karlsruhe (Energieanbieter) als Muttergesellschaft der Yello Strom GmbH, Siegburger Staße 229, 50679 Köln"
          }
        }
      },
      "enbw": {
        "title": {
          "offers": "Informationen und Angebote erhalten",
          "offersNewEcoProvider": "Erweiterung der Zustimmung",
          "profiling2": "Interessenprofil für Beratung",
          "commercialDataSharing": "Interessenprofil anreichern",
          "googleCustomerMatch": "Personalisierte Google Werbung"
        },
        "text": {
          "profiling2": "Die EnBW darf zur Erstellung eines Interessenprofils die zu meiner Person gespeicherten Daten mit meinen Online-Daten zusammenführen.",
          "commercialDataSharing": "Die EnBW darf die bei den Partnern zu meiner Person gespeicherten Daten erheben und zur Erstellung eines Interessenprofils verwenden. Die EnBW nutzt dieses Profil, um mich individuell zu beraten. Die Partner sind folgende Tochterunternehmen der EnBW: Yello Strom GmbH, DZ-4 GmbH, SENEC GmbH und EnBW mobility+ AG & Co. KG.",
          "offers": "Die EnBW Energie Baden-Württemberg AG (EnBW) darf mir künftig eigene personalisierte Informationen, Angebote sowie Umfragen zum vielfältigen Angebots- und Serviceprogramm der EnBW per Telefon, E-Mail und SMS übermitteln. Hierzu zählt auch das Angebots- und Serviceprogramm der folgenden Tochterunternehmen der EnBW: EnBW mobility+ AG & Co. KG, Yello Strom GmbH, SENEC GmbH, DZ-4 GmbH. Bei empfangenen E-Mails darf mein Öffnungs- und Klickverhalten ausgewertet und genutzt werden.",
          "offersNewEcoProvider": "Die EnBW darf mich über die Möglichkeit informieren, meine Einwilligung zu erweitern, wenn neue Partner hinzukommen.",
          "googleCustomerMatch": "Um mir personalisierte Angebote in der Google Suche, auf dem Shopping-Tab, in Gmail, auf YouTube und im Displaynetzwerk anzeigen zu können, verwendet die EnBW den Google Kundenabgleich.",
          "googleCustomerMatchMoreCta": "Google Kundenabgleich"
        }
      },
      "yello": {
        "title": {
          "offers": "Informationen und Angebote erhalten",
          "offersNewEcoProvider": "Erweiterung der Zustimmung",
          "profiling2": "Interessenprofil für Beratung",
          "commercialDataSharing": "Interessenprofil anreichern",
          "googleCustomerMatch": "Personalisierte Google Werbung"
        },
        "text": {
          "profiling2": "Yello darf zur Erstellung eines Interessenprofils die zu meiner Person gespeicherten Daten mit meinen Online-Daten zusammenführen.",
          "commercialDataSharing": "Yello darf die bei den Partnern zu meiner Person gespeicherten Daten erheben und zur Erstellung eines Interessenprofils verwenden. Yello nutzt dieses Profil, um mich individuell zu beraten. Die Partner sind: EnBW Energie Baden-Württemberg AG als Muttergesellschaft der Yello und die beteiligten Schwestergesellschaften DZ-4 GmbH, SENEC GmbH und EnBW mobility+ AG & Co. KG.",
          "offers": "Die Yello Strom GmbH (Yello) darf mir künftig eigene personalisierte Informationen, Angebote sowie Umfragen zum vielfältigen Angebots- und Serviceprogramm der Yello per Telefon, E-Mail und SMS übermitteln. Hierzu zählt auch das Angebots- und Serviceprogramm der Muttergesellschaft EnBW AG und der folgenden Schwesterunternehmen der Yello: DZ-4 GmbH, SENEC GmbH und EnBW mobility+ AG & Co. KG. Bei empfangenen E-Mails darf mein Öffnungs- und Klickverhalten ausgewertet und genutzt werden.",
          "offersNewEcoProvider": "Yello darf mich über die Möglichkeit informieren, meine Einwilligung zu erweitern, wenn neue Partner hinzukommen.",
          "googleCustomerMatch": "Um mir personalisierte Angebote in der Google Suche, auf dem Shopping-Tab, in Gmail, auf YouTube und im Displaynetzwerk anzeigen zu können, verwendet Yello den Google Kundenabgleich.",
          "googleCustomerMatchMoreCta": "Google Kundenabgleich"
        }
      }, 
      "channels": {
        "title": "Auf diesen Wegen möchte ich benachrichtigt werden"
      },
      "footer": {
        "confirm": "Meine Auswahl bestätigen",
        "deactivateAll": "Alles ablehnen",
        "activateAll": "Alles erlauben"
      }
    },
    "adv-permissions": {
      "title": "Werbeeinstellungen",
      "enbw": {
        "description": "Hier können Sie festlegen, wie Sie von uns über aktuelle Angebote informiert werden möchten und welche Themen Sie dabei interessieren.",
        "title": {
          "electricity": "Strom",
          "gas": "Gas",
          "heatSupplyAndHeating": "Wärmelieferung und -erzeugung",
          "solarSystems": "Solaranlagen",
          "meterOperation": "Messstellenbetrieb",
          "energyStorage": "Energiespeicher",
          "participationModelEnergyProduction": "Beteiligungsmodelle (an Energieerzeugungsanlagen)",
          "energyRelatedServices": "Energienahe Dienstleistungen",
          "emobility": "Elektromobilität",
          "telecommunications": "Telekommunikation (Festnetz-, Internet-, Mobilfunktarife)",
          "customerBenefitPrograms": "Kundenvorteilsprogramme"
        },
        "text": "Hiermit stimme ich zu, dass die EnBW Energie Baden-Württemberg AG mich zu den oben angegebenen Themen über die ausgewählten Kommunikationskanäle kontaktieren darf, mein Öffnungs- und Klickverhalten in empfangenen E-Mails individuell auswerten und nutzen, sowie meinen ausgewählten Kommunikationskanal zum Zweck der Kontaktaufnahme verarbeiten darf. Die Erteilung ist freiwillig und ich kann sie jederzeit hier ganz oder in Teilen widerrufen, ohne dass die Rechtmäßigkeit der bisherigen Datenverarbeitung berührt wird."
      },
      "yello": {
        "description": "Hier können Sie festlegen, wie Sie von uns über aktuelle Angebote informiert werden möchten und welche Themen Sie dabei interessieren.",
        "title": {
          "electricity": "Strom",
          "gas": "Gas",
          "energyRelatedServices": "energienahe Dienstleistungen",
          "photovoltaic": "Photovoltaik",
          "emobility": "E-Mobilität",
          "streaming": "Streaming (Musik, Film)",
          "insurances": "Versicherungen (Haushalt, Hardwaresicherheit)",
          "electricalAppliances": "Elektroklein- und -großgeräte inklusive Zubehör",
          "customerBenefitPrograms": "Kundenvorteilsprogramme"
        },
        "text": "Hiermit stimme ich zu, dass die Yello Strom GmbH mich zu den oben angegebenen Themen über die ausgewählten Kommunikationskanäle kontaktieren darf, mein Öffnungs- und Klickverhalten in empfangenen E-Mails individuell auswerten und nutzen, sowie meinen ausgewählten Kommunikationskanal zum Zweck der Kontaktaufnahme verarbeiten darf. Die Erteilung ist freiwillig und ich kann sie jederzeit hier ganz oder in Teilen widerrufen, ohne dass die Rechtmäßigkeit der bisherigen Datenverarbeitung berührt wird."
      },
      "channels": {
        "title": "Kanäle"
      },
      "topics": {
        "title": "Themen",
        "all": { "titleActivated": "Alle deaktivieren", "titleDeactivated": "Alle aktivieren" }
      },
      "footer": {
        "save": "Speichern"
      }
    },
    "permissions-product-revocation": {
      "title": "Gesetzlicher Erlaubnis widersprechen",
      "titleBulkRevocation": "Möchten Sie auch der gesetzlichen Erlaubnis widersprechen?",
      "paragraph1": "Ich möchte zukünftig keine Informationen zu {0} Produkten per Brief erhalten.",
      "paragraph1BulkRevocation": "Ja, ich möchte zukünftig keine Informationen zu {0} Produkten per Brief erhalten.",
      "warning": "Ich weiß, dass ich diese Entscheidung nicht mehr rückgängig machen kann.",
      "paragraph2": "Übrigens: Vertragsrelevante Kommunikation per Brief versenden wir weiterhin. Ausgenommen sind Kunden, die sich für Onlinekommunikation entschieden haben.",
      "actions": {
        "confirm": "Ja, ich bin mir sicher",
        "confirmBulkRevocation": "Auch Erlaubnis widersprechen",
        "dismiss": "Abbrechen",
        "dismissBulkRevocation": "Überspringen"
      }
    },
    "google-customer-match-info": {
      "title": "Google Kundenabgleich",
      "paragraph1": {
        "enbw": "Um Ihnen diese Angebote zielgerichtet anzeigen zu können, übermitteln wir Ihre E-Mail-Adresse verschlüsselt als Hashwert an:",
        "yello": "Um dir diese Angebote zielgerichtet anzeigen zu können, übermitteln wir deine E-Mail-Adresse verschlüsselt als Hashwert an:"
      },
      "address": "Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.",
      "paragraph2": "Die Google Ireland Limited gleicht diesen Hashwert dann mit ihren eigenen Hashwerten ab, welche aus den bei der Google Ireland Limited vorliegenden E-Mail-Adressen errechnet werden. Die übereinstimmenden Hashwerte werden dann dazu verwendet, Ihnen und Interessenten mit ähnlichen Präferenzen, gezielt unsere Produkte und Dienstleistungen über die Werbeflächen bei Google anzubieten.  Wir können hierbei bestimmen wie wir unsere Zielgruppen gestalten wollen, also beispielsweise nach Alter, Sprache, Geschlecht, Standort vorselektieren. Wer aber letztlich tatsächlich unsere Produkte angezeigt bekommt entscheidet Google. Nähere Informationen zur Datenverwendung durch Google im Rahmen vom Google Kundenabgleich finden Sie {0}.",
      "googlePrivacyPolicyLink": "hier"
    },
    "tracking-permissions": {
      "title": "Persönliches Kundenprofil",
      "enbw": {
        "profiling": {
          "title": "Ich bin mit der Erstellung eines persönlichen Kundenprofils einverstanden.",
          "text": "Dadurch kann die EnBW AG ein besseres und individuelleres Erlebnis für Sie schaffen und Ihnen online sowie offline auf Sie zugeschnittene Services und Angebote unterbreiten."
        },
        "advantages": {
          "title": "Ein persönliches Kundenprofil bietet folgende Vorteile:",
          "advantage1": "Die EnBW kann Sie dann ganz individuell auf relevante Inhalte hinweisen. Fehlt z.B. noch Ihr monatlicher Zählerstand, erinnern wir Sie.",
          "advantage2": "Die EnBW kann Ihnen mit Ihrem individuellen Kundenprofil nur die Angebote senden, die Sie wahrscheinlich interessieren. Z.B.: Sie haben eine alte Waschmaschine? Wir hätten vielleicht den passenden Tarif inklusive einer Top-Waschmaschine für Sie.",
          "advantage3": "Die EnBW kann Sie Ihren persönlichen Bedürfnissen entsprechend beraten. Z.B. können wir Ihnen mehr Transparenz rund um Ihren Energieverbrauch und Ihre Kosten geben und dabei helfen, unnötigen Verbrauch zu vermeiden."
        },
        "dataSharing": {
          "title": "Hierzu wollen wir folgende Datenkategorien zu Ihrer Person zusammenführen und auswerten:",
          "digitalOffers": {
            "title": "Daten, die bei der Nutzung unserer digitalen Angebote bezüglich Ihres Online Nutzungsverhaltens erhoben werden",
            "text": "Hierunter versteht die EnBW Daten, die bei einem Besuch der EnBW Webseite und  Social Media Präsenzen, bei der Nutzung einer der EnBW Apps sowie bei Versendung von EnBW Newslettern oder Push-Nachrichten erhoben werden. Im Einzelnen sind dies beispielsweise Informationen darüber, welche Seiten Sie sich ansehen, wann und wie lange Sie uns besuchen bzw. unsere Apps nutzen, ob Sie unsere Newsletter lesen und welche Endgeräte Sie hierzu verwenden. Welche Daten im Detail und auf Basis welcher Rechtsgrundlage im Rahmen des jeweiligen digitalen Angebotes erhoben werden, erfahren Sie in den Datenschutzinformationen des jeweiligen digitalen Angebots."
          },
          "customerAndContractData": {
            "title": "Kunden- und vertragsbezogene Daten",
            "text": "Hierunter verstehen wir Daten, welche wir im Rahmen einer vorvertraglichen oder vertraglichen Beziehung von Ihnen erhalten haben. Dies sind beispielsweise ihr Name, Kontaktdaten, vertragliche Details wie Ihr Tarif und ihr Verbrauch und auch technische oder bauliche Daten, sofern deren Erhebung im Rahmen unserer (vor-)vertraglichen Beziehung erfolgte (z.B. Dachfläche, Anlagenleistungsdaten)."
          },
          "otherData": {
            "title": "Weitere zur Verfügung gestellte Daten",
            "text": "Hierunter verstehen wir alle Daten, welche Sie uns außerhalb unserer vertraglichen oder vorvertraglichen Beziehung selbst gegeben oder mittels unserer Anwendungen generiert haben. Dies kann zum Beispiel ihr geäußertes Interesse an einem unserer Produkte oder unserer Dienstleistungen sein, aber auch z.B. ein Hinweis auf einen bevorstehenden Umzug oder sonstige wohnliche Veränderungen, welche Auswirkungen auf den für Sie optimalen Tarif haben könnten. Ein weiteres Beispiel ist bei Nutzung unserer E-Mobility Fahrsimulation die Information, ob ein E-Auto für Sie sinnvoll sein könnte."
          },
          "communicationWithMe": {
            "title": "Daten, die wir im Rahmen Ihrer Kommunikation mit uns erheben",
            "text": "Hierunter verstehen wir Daten, die wir im Zusammenhang mit einer Kommunikation mit Ihnen zusätzlich zu den inhaltlich mitgeteilten Informationen erheben. Dies betrifft insbesondere unsere Einschätzung Ihrer Zufriedenheit."
          }
        },
        "legalParagraph1": "Bitte beachten Sie, dass die Erteilung/Nichterteilung der Einwilligung keinen Einfluss auf Datenverarbeitungen hat, die bereits auf Basis anderer Rechtsgrundlagen erlaubt sind. Die Einwilligung können Sie jederzeit widerrufen. Die Rechtmäßigkeit der Datenverarbeitung auf Basis der Einwilligung bis zum Widerruf bleibt unberührt. Unsere Widerrufsmöglichkeiten: In {0} sowie EnBW Energie Baden-Württemberg AG, Durlacher Allee 93, 76131 Karlsruhe.",
        "legalParagraph1Link": "Meine EnBW",
        "legalParagraph2": "Die Datenschutzinformationen und Hinweise zu Ihrem datenschutzrechtlichen Widerspruchsrecht finden Sie {0}.",
        "legalParagraph2Link": "hier"
      },
      "yello": {
        "profiling": {
          "title": "Ich bin mit der Erstellung eines persönlichen Kundenprofils einverstanden.",
          "text": "Dadurch kann die Yello Strom GmbH ein besseres und individuelleres Erlebnis für mich schaffen und mir online sowie offline auf mich zugeschnittene Services und Angebote unterbreiten."
        },
        "advantages": {
          "title": "Ein persönliches Kundenprofil bietet folgende Vorteile:",
          "advantage1": "Yello kann mich dann ganz individuell auf relevante Inhalte hinweisen, die nur für mich relevant sind. Yello kann mich z.B. erinnern, falls mein monatlicher Zählerstand fehlt.",
          "advantage2": "Yello kann mir solche Angebote senden, die mich wahrscheinlich interessieren. Angenommen, ich könnte eine neue Waschmaschine gebrauchen. Dann hat Yello vielleicht den passenden Tarif inklusive einer Top-Waschmaschine für mich.",
          "advantage3": "Yello kann mich meinen persönlichen Bedürfnissen entsprechend beraten. Z.B. kann mir Yello mehr Transparenz rund um meinen Energieverbrauch und meine Kosten geben und dabei helfen, unnötigen Verbrauch zu vermeiden."
        },
        "dataSharing": {
          "title": "Hierzu würde Yello folgende Datenkategorien zu meiner Person zusammenführen und auswerten:",
          "digitalOffers": {
            "title": "Daten, die bei der Nutzung unserer digitalen Angebote bezüglich meines Online Nutzungsverhaltens erhoben werden",
            "text": "Hierunter versteht Yello Daten, die bei meinem Besuch der Yello Webseite und Social Media Präsenzen, bei der Nutzung einer der Yello Apps sowie bei Versendung von Yello Newslettern oder Push-Nachrichten erhoben werden. Im Einzelnen sind dies beispielsweise Informationen darüber, welche Seiten ich mir ansehe, wann und wie lange ich diese besuche bzw. diese Apps nutze, ob ich diese Newsletter lese und welche Endgeräte ich hierzu verwende. Welche Daten im Detail und auf Basis welcher Rechtsgrundlage im Rahmen des jeweiligen digitalen Angebotes erhoben werden, erfahre ich in den Datenschutzinformationen des jeweiligen digitalen Angebots."
          },
          "customerAndContractData": {
            "title": "Kunden- und vertragsbezogene Daten",
            "text": "Hierunter werden Daten verstanden, welche Yello im Rahmen einer vorvertraglichen oder vertraglichen Beziehung von mir erhalten hat. Dies sind beispielsweise mein Name, Kontaktdaten, vertragliche Details wie mein Tarif und mein Verbrauch und auch technische oder bauliche Daten, sofern deren Erhebung im Rahmen der (vor-)vertraglichen Beziehung erfolgte (z.B. Dachfläche, Anlagenleistungsdaten)."
          },
          "otherData": {
            "title": "Weitere zur Verfügung gestellte Daten",
            "text": "Hierunter versteht Yello alle Daten, welche ich außerhalb der vertraglichen oder vorvertraglichen Beziehung selbst gegeben oder mittels Yello Anwendungen generiert habe. Dies kann zum Beispiel mein geäußertes Interesse an einem der Yello Produkte oder Dienstleistungen sein, aber auch z.B. ein Hinweis auf einen bevorstehenden Umzug oder sonstige wohnliche Veränderungen, welche Auswirkungen auf den für mich optimalen Tarif haben könnten. Ein weiteres Beispiel ist bei Nutzung der Yello E-Mobility Fahrsimulation die Information, ob ein E-Auto für mich sinnvoll sein könnte."
          },
          "communicationWithMe": {
            "title": "Daten, die Yello im Rahmen ihrer Kommunikation mit mir erhebt",
            "text": "Hierunter werden Daten verstanden, die im Zusammenhang mit einer Yello Kommunikation mit mir zusätzlich zu den inhaltlich mitgeteilten Informationen erhoben werden. Dies betrifft insbesondere die Einschätzung meiner Zufriedenheit."
          }
        },
        "legalParagraph1": "Die Erteilung/Nichterteilung der Einwilligung hat keinen Einfluss auf Datenverarbeitungen, die bereits auf Basis anderer Rechtsgrundlagen erlaubt sind. Die Einwilligung kann ich jederzeit widerrufen: In der Yello App, in {0}, Yello Strom GmbH, Siegburger Straße 229, 50679 Köln, immerda{'@'}yello.de. Die Rechtmäßigkeit der Datenverarbeitung auf Basis der Einwilligung bis zum Widerruf bleibt unberührt.",
        "legalParagraph1Link": "Mein Yello",
        "legalParagraph2": "Unsere Datenschutzhinweise und die Hinweise zu deinem datenschutzrechtlichen Widerspruchsrecht findest du {0}.",
        "legalParagraph2Link": "hier"
      },
      "learnMore": "Mehr{0}erfahren.",
      "footer": {
        "save": "Speichern"
      }
    },
    "paperless-communication": {
      "title": "Auf E-Mail Kommunikation umstellen?",
      "text": "Erhalten Sie Ihre Rechnungen und wichtige Infos zu Ihren Verträgen bequem per E-Mail {0} - jederzeit verfügbar, egal, wo Sie gerade sind. Gut für Sie gut für die Umwelt.",
      "actions": {
        "confirm": "E-Mail-Adresse wählen",
        "confirm-single": "E-Mail-Kommunikation nutzen",
        "skip": "Weiterhin Postweg nutzen"
      }
    },
    "email-communication-channels": {
      "title": "E-Mail für Vertragskommunikation wählen",
      "title-single-option": "Diese E-Mail-Adresse für Ihre Vertrags-Kommunikation nutzen:",
      "contract-caption": "Für {0} Vertrag genutzt",
      "contact-caption": "Für Werbung genutzt",
      "registration-email-caption": "E-Mail für Anmeldung",
      "actions": {
        "select": "E-Mail-Adresse nutzen"
      }
    }
  },

  "overview": {
    "services-title": "Konto nutzbar für",
    "welcome-profile-info": "Willkommen, {name}",
    "profile-info": "Verwalten Sie hier Ihr zentrales Nutzerkonto aller Energiedienste der Energie Baden-Württemberg-Gruppe.",
    "services": {
      "yelloApp": "Yello App",
      "yelloHint": "Verbrauch & Kosten im Blick.",
      "myYello": "Mein Yello Web",
      "myYelloHint": "Immer alles im Blick.",
      "mobilityApp": "EnBW mobility+ App",
      "mobilityHint": "Bequem Ladestationen finden.",
      "houseApp": "EnBW zuhause+ App",
      "houseHint": "Nachzahlungen vermeiden.",
      "myEnbw": "Meine EnBW Web",
      "myEnbwHint": "Strom, Gas & Wärme."
    },
    "paperless-communication-prompt": {
      "captionMultiple": "Erhalten Sie Ihre Rechnungen und  wichtige Infos zu Ihren Verträgen bequem per E-Mail.",
      "captionSingle": "Erhalten Sie Ihre Rechnungen und  wichtige Infos zu Ihren Verträgen bequem per E-Mail an: {email}",
      "action": "E-Mail-Kommunikation aktivieren"
    }
  },

  "personal-data": {
    "name": "Name",
    "birthday": "Geburtsdatum",
    "general": "Allgemein",
    "contact": "Kontakt",
    "email": {
      "label": "E-Mail",
      "single-hint": "+1 weitere Adresse",
      "multiple-hint": "+{count} weitere Adressen"
    },
    "contract": {
      "label": "Vertrags­nummer",
      "single-hint": "Für 1 Vertrag genutzt",
      "multiple-hint": "Für {count} Verträge genutzt"
    },
    "phone": {
      "label": "Telefon",
      "single-hint": "+1 weitere Nummer",
      "multiple-hint": "+{count} weitere Nummern"
    },
    "payment-method": {
      "label": "Zahlungsarten",
      "sidebar-title": "Wofür werden diese Zahlungsarten verwendet?",
      "single-hint": "+1 weitere Zahlungsart",
      "multiple-hint": "+{count} weitere Zahlungsarten"
    },
    "address": {
      "label": "Adresse",
      "single-hint": "+1 weitere Adresse",
      "multiple-hint": "+{count} weitere Adressen"
    },
    "relative-date": {
      "today": "Zuletzt geändert: Heute",
      "yesterday": "Zuletzt geändert: Gestern",
      "day-before-yesterday": "Zuletzt geändert: Vorgestern",
      "never-changed": "Noch nie geändert",
      "last-changed-date": "Zuletzt geändert: {date}"
    },
    "sidebar-card": {
      "title-1": "Wo kommen meine persönlichen Daten her?",
      "text-1": "Ihre Daten werden aus den mit dem myEnergyKey-Konto verknüpften Verträgen übertragen.",
      "title-2": "Warum werden nicht alle Daten angezeigt?",
      "text-2": "Aktuell sind noch nicht alle Energiedienste der EnBW-Gruppe mit dem myEnergyKey-Konto verbunden."
    }
  },

  "paperless-communication": {
    "notifications": {
      "chosen": "Abhängig von Ihren individuellen Einstellungen erhalten Sie Ihre Rechnungen und vertragsbezogene Kommunikation nun per E-Mail.",
      "failed": "Änderung fehlgeschlagen. Bitte versuchen Sie es später noch einmal.",
      "paper-kept": "Sie erhalten Ihre Rechnungen und vertragsbezogene Kommunikation weiterhin per Post."
    }
  },

  "registration-security": {
    "email-reset": {
      "title": "E-Mail zur Anmeldung",
      "sidebar-title": "Wofür wird die E-Mail zur Anmeldung verwendet?",
      "content": "Mit dieser Email melden Sie sich bei allen Services an, die Sie mit Ihrem myEnergyKey-Konto verknüpft haben.",
      "button": "E-Mail zur Anmeldung ändern"
    },
    "password-reset": {
      "safety-step": "Passwort zurücksetzen",
      "security-step-detail": "Ein zusätzlicher Sicherheitsschritt ist erforderlich, um Ihre Identität zu prüfen. Wir haben Ihnen eine E-Mail zum ändern Ihres Passworts an folgende Adresse gesendet",
      "follow-instructions-detail": "Bitte öffnen Sie die E-Mail und folgen Sie den Anweisungen.",
      "follow-instructions-detail-long": "Bitte öffnen Sie die E-Mail und folgen Sie den Anweisungen. Falls Sie keine E-Mail erhalten haben, können Sie sich die E-Mail erneut zusenden."
    },
    "pin-reset": {
      "label": "Telefon-Pin",
      "content": "Diese PIN müssen Sie angeben, wenn Sie uns telefonisch kontaktieren.",
      "sidebar-title": "Wofür wird die Telefon-Pin verwendet?",
      "sidebar-content": "Diese PIN müssen Sie angeben, wenn Sie uns telefonisch kontaktieren.",
      "actions": {
        "reset": {
          "button": "Telefon-Pin ändern"
        },
        "view": {
          "button": "Telefon-Pin anzeigen"
        },
        "hide": {
          "button": "Telefon-Pin verbergen"
        }
      }
    },
    "resend-email": {
      "no-email-received": "Keine E-Mail erhalten?",
      "resend": "Erneut senden",
      "resend-button": "E-Mail erneut zusenden",
      "resend-counter": "Erneut senden (in {seconds} Sekunden)"
    },  
    "password": {
      "title": "Passwort",
      "content": "Mit diesem Passwort melden Sie sich bei allen Services der EnBW, Yello und SENEC an, die Sie mit Ihrem myEnergyKey verknüpft haben. Um Ihr Passwort zu ändern klicken Sie auf “Passwort zurücksetzen”.",
      "sidebar-title": "Wofür wird dieses Passwort verwendet?",
      "sidebar-content": "Mit diesem Passwort melden Sie sich bei allen Services der EnBW, Yello und SENEC an, die Sie mit Ihrem myEnergyKey verknüpft haben. Um Ihr Passwort zu ändern klicken Sie auf “Passwort zurücksetzen”. Anschließend können Sie ihr Passwort ändern."
    },
    "access-data": "Zugangsdaten",
    "reset-password": "Passwort zurücksetzen",
    "username": "Benutzername",
    "password-label": "Passwort"
  },

  "data-privacy": {
    "tracking-permissions-section": {
      "title": "Persönliches Kundenprofil",
      "content": "Verwalten Sie Ihre Zustimmung zu Ihrem Kundenprofil, um personalisierte Services und Angebote zu erhalten."
    },
    "advertisement-permissions-section": {
      "title": "Werbeeinstellungen",
      "content": "Legen Sie fest, wie und zu welchen Themen Sie Angebote erhalten möchten.",
      "link": "Werbezustimmung verwalten"
    },
    "eco-permission-section": {
      "title": "Werbeeinstellungen",
      "content": "Entscheiden Sie ob und wo wir Sie für persönliche Angebote kontaktieren dürfen."
    },
    "consumption-section": {
      "title": "Verbrauchsdarstellung",
      "content": "Verwalten Sie Ihre Einwilligung zur visuellen Darstellung Ihres Verbrauchs."
    },
    "data-visualization-section": {
      "title": "Einwilligung zur Datenvisualisierung",
      "content": "Um Ihre Verbrauchsdaten zu analysieren zu können, benötigen wir Ihre Einwilligung zur Datenverarbeitung.",
      "link": "Einwilligung zur Datenvisualisierung"
    },
    "cookie-consent-section": {
      "title": "Cookie-Einstellungen",
      "content": "Legen Sie fest, welche Arten von Cookies Sie in diesem Browser zulassen möchten.",
      "link": "Bearbeiten"
    },
    "delete-mek-section": {
      "title": "myEnergyKey Zugang löschen",
      "content": "Hier können Sie Ihren myEnergyKey löschen und damit den Zugang zu allen genutzen Services."
    },
    "delete-mek-page": {
      "title": "myEnergyKey Zugang löschen",
      "content": "Bitte beachten Sie, dass Sie durch das Löschen Ihres myEnergyKey: {email} den Zugriff auf alle damit verknüpften Dienste verlieren. Eine Löschung von Daten, die in den jeweiligen Services verwendet werden, findet nicht statt. Das Löschen des myEnergyKey Zugangs führt auch nicht zu einer Kündigung aktiver Services. Kostenpflichtige Verträge werden fortgesetzt.",
      "button": "Zugang löschen",
      "dialog-button": "Zugang endgültig löschen",
      "error-toast": {
        "title": "Löschen fehlgeschlagen",
        "text": "Versuchen Sie es später nocheinmal"
      },
      "success": {
        "title": "Zugang wird gelöscht",
        "text": "Wir haben Ihre Anfrage zum Löschen Ihres myEnergyKey-Zugangs erhalten. Dieser Vorgang kann bis zu 4 Tagen dauern."
      }
    },
    "edit-permissions-notifications": {
      "success": {
        "title": "Einstellungen gespeichert"
      },
      "error": {
        "title": "Änderung fehlgeschlagen",
        "content": "Versuchen Sie es später nocheinmal"
      }
    },
    "permissions-revoke-product-consent-notifications": {
      "success": {
        "title": "Einstellungen gespeichert",
        "content": "Sie erhalten keine Informationen mehr zu {0} Produkten per Brief."
      },
      "error": {
        "title": "Änderung fehlgeschlagen",
        "content": "Versuchen Sie es später nocheinmal"
      }
    },
    "active": "Aktiv",
    "inActive": "Deaktiviert",
    "sidebar-card": {
      "title-1": "Wir nehmen Datenschutz sehr ernst",
      "text-1": "Wir verpflichten uns, Ihre Daten zu schützen. Weitere Informationen zu diesem Thema finden Sie in unserer",
      "link-1": "Datenschutzerklärung"
    }
  },

  "emails-page": {
    "sidebar-title": "Welche E-Mail-Adressen werden hier angezeigt?",
    "content": "Hier sehen Sie alle E-Mail-Adressen aus Ihren mit dem myEnergyKey-Konto verknüpften Energiediensten.",
    "registration-emails": {
      "title": "E-Mail zur Anmeldung",
      "sidebar-title": "Wofür wird die E-Mail zur Anmeldung verwendet?",
      "content": "Mit dieser Adresse melden Sie sich bei allen Services an, die Sie mit Ihrem myEnergyKey-Konto verknüpft haben."
    },
    "contract-emails": {
      "title": "Vertragskontakt",
      "all-title": "Alle Vertragskontakte",
      "sidebar-title": "Wofür wird der Vertragskontakt verwendet?",
      "content": "An diese Adresse senden wir Ihnen Rechnungen und wichtige Informationen zu Ihren Verträgen. | An diese Adressen senden wir Ihnen Rechnungen und wichtige Informationen zu Ihren Verträgen.",
      "single-page-content": "An diese E-Mail-Adresse senden wir Rechnungen und Vertragsinformationen zu den unten stehenden Verträgen."
    },
    "contact-emails": {
      "title": "Werbekontakt",
      "all-title": "Alle Werbekontakte",
      "sidebar-title": "Wofür wird der Werbekontakt verwendet?",
      "content": "An diese Adresse senden wir Ihnen Informationen und Angebote zu relevanten Themen und Services. | An diese Adressen senden wir Ihnen Informationen und Angebote zu relevanten Themen und Services."
    },
    "edit-button": "E-Mail-Adresse ändern"
  },

  "phones-page": {
    "sidebar-title": "Welche Telefonnummern werden hier angezeigt?",
    "content": "Hier sehen Sie alle Telefonnummern aus Ihren mit dem myEnergyKey-Konto verknüpften Energiediensten.",
    "contract-phones": {
      "title": "Vertragskontakt",
      "content": "Auf dieser Nummer kontaktieren wir Sie zu wichtigen Vertragsangelegenheiten. | Auf diesen Nummern kontaktieren wir Sie zu wichtigen Vertragsangelegenheiten.",
      "all-title": "Alle Vertragskontakte",
      "sidebar-title": "Wofür wird der Vertragskontakt verwendet?",
      "single-page-sidebar-title": "Wofür wird der Vertragskontakt verwendet?"
    },
    "contact-phones": {
      "title": "Werbekontakt",
      "content": "Auf dieser Nummer kontaktieren wir Sie, um Sie mit relevanten Informationen und Angeboten zu versorgen. | Auf diesen Nummern kontaktieren wir Sie, um Sie mit relevanten Informationen und Angeboten zu versorgen.",
      "all-title": "Alle Werbekontakte",
      "sidebar-title": "Wofür wird der Werbekontakt verwendet?",
      "single-page-sidebar-title": "Wofür wird der Werbekontakt verwendet?"
    },
    "edit-button": "Telefonnummer ändern",
    "permission-setting": "Werbeeinstellungen"
  },

  "addresses-page": {
    "sidebar-title": "Welche Adressen werden hier angezeigt?",
    "content": "Hier sehen Sie alle Adressen aus Ihren mit dem myEnergyKey-Konto verknüpften Energiediensten.",
    "delivery-address": {
      "title": "Versorgungs-Adresse",
      "all-title": "Alle Versorgungs-Adressen",
      "sidebar-title": "Wofür werden die Versorgungs-Adressen verwendet?",
      "content": "Diese Adresse versorgen wir mit den bei uns gebuchten Leistungen.",
      "single-page-sidebar-title": "Wofür wird die Versorgungs-Adresse verwendet?",
      "single-page-content": "Diese Adresse versorgen wir mit den bei uns gebuchten Leistungen. Sie können diese Adresse nur bei einem Umzug ändern.",
      "edit-button": "Umzug beantragen"
    },
    "billing-address": {
      "title": "Vertrags-Anschrift",
      "all-title": "Alle Vertrags-Adressen",
      "sidebar-title": "Wofür werden die Vertrags-Anschriften verwendet?",
      "content": "An diese Anschrift senden wir Rechnungen und wichtige Informationen zu Ihren Verträgen.",
      "single-page-sidebar-title": "Wofür wird die Vertrags-Anschrift verwendet?",
      "single-page-content": "An diese Anschrift senden wir Rechnungen und wichtige Informationen zu Ihren Verträgen.",
      "edit-button": "Adresse ändern"
    },
    "contact-address": {
      "title": "Kontakt-Anschrift",
      "all-title": "Alle Kontakt-Adressen",
      "sidebar-title": "Wofür werden die Kontakt-Anschriften verwendet?",
      "content": "An diese Anschrift senden wir Informationen und Angebote zu den von Ihnen gewünschten Themen und Services.",
      "single-page-sidebar-title": "Wofür wird die Kontakt-Anschrift verwendet?",
      "edit-button": "Adresse ändern"
    }
  },

  "payments-page": {
    "no-data-label": "Bankdaten",
    "content": "Diese Zahlungsarten verwenden wir für anfallende Abbuchungen oder Gutschriften zu Ihren Verträgen.",
    "self-payment": {
      "title": "Überweisung",
      "content": "Sie überweisen die Kosten für die unten stehenden Verträge selbst."
    },
    "self-payment-with-bank-account": {
      "title": "Konto für Gutschriften",
      "content": "Diese Bankverbindung nutzen wir für Gutschriften zu den unten stehenden Verträgen."
    },
    "bank-account": {
       "title": "Bankeinzug",
       "content" : "Diese Bankverbindung nutzen wir für Abbuchungen und Gutschriften zu den unten stehenden Verträgen."
    },
    "edit-button": "Bankverbindung ändern",
    "self-payment-info-card": {
      "content": "Zahlung vereinfachen und Lastschriftverfahren nutzen.",
      "link": "SEPA-Mandat erteilen"
    },
    "no-account-info-card": {
      "content": "Es konnte keine gültige Bankverbindung gefunden werden.",
      "link": "Bankverbindung hinzufügen"
    }
  },

  "edit-page": {
    "phone-number": {
      "title": "Ihre neue Nummer",
      "placeholder": "z.B. 0721 72586001",
      "hint": "Bitte geben Sie die Telefonnummer in diesem Format ein: +49123456789",
      "error": "Geben Sie eine gültige Telefonnummer an.",
      "country-code-error": "Geben Sie eine gültige Ländervorwahl an (z.B. für Deutschland +49 oder 0049).",
      "button": "Nummer speichern",
      "toast": {
        "error": {
          "title": "Änderung fehlgeschlagen",
          "content": "Versuchen Sie es später noch einmal"
        },
        "partial-error": {
          "title": "Änderung teilweise fehlgeschlagen",
          "content": "Nummer konnte nicht für alle Verträge geändert werden."
        },
        "success": {
          "title": "Telefonnummer geändert"
        }
      }
    }
  },

  "data-protection": {
    "title": "Datenschutzerklärung",
    "title-cookie-consent": "Änderung Ihrer Einstellungen zu Cookies und Privatsphäre",
    "text-cookie-consent-p1-1": "Wir respektieren Ihre Privatsphäre. Egal, ob Sie unseren Cookies bereits zugestimmt haben oder nicht -",
    "text-cookie-consent-p1-a": "hier",
    "text-cookie-consent-p1-2": "können Sie Ihre Zustimmung auch nachträglich noch erteilen oder widerrufen.",
    "text-1": "Datenschutzinformationen für die Nutzung der Webseite www.myenergykey.de",
    "text-2": "Stand: 01. November 2023",
    "text-3": "Wir, die EnBW Energie Baden-Württemberg AG, nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst. Im Folgenden wollen wir Sie darüber informieren welche personenbezogenen Daten wir beim Besuch unserer Webseite erheben, wie wir diese Daten verarbeiten und welche Rechte Ihnen im Zusammenhang mit Ihren personenbezogenen Daten zustehen.",
    "title-1": "1. Wer ist verantwortlich für die Verarbeitung meiner Daten?",
    "text-5": "Verantwortlich für die Verarbeitung Ihrer Daten ist:",
    "text-6": "EnBW Energie Baden-Württemberg AG",
    "text-7": "Durlacher Allee 93",
    "text-8": "76131 Karlsruhe",
    "text-phone-label": "Telefonzentrale:",
    "text-customer-service-number": "Kundenservicenummer: 0721 72586-001",
    "text-9": "E-Mail:",
    "text-contact-advice": "Bei Fragen, Anregungen oder Beschwerden können Sie uns unter den oben angegebenen Kontaktdaten erreichen.",
    "title-2": "2. Wie kann ich den Datenschutzbeauftragten erreichen?",
    "text-10": "Unseren Datenschutzbeauftragten erreichen Sie unter",
    "text-11": "Er steht Ihnen für Fragen zum Datenschutz gerne zur Verfügung.",
    "title-3": "3. Wie werden meine Daten verarbeitet, wenn ich die Webseite besuche?",
    "text-13": "Wir erheben und verarbeiten Ihre Daten ausschließlich dann, wenn wir entweder Ihre Einwilligung zur Datenverarbeitung erhalten haben oder die Verarbeitung gesetzlich erlaubt ist.",
    "title-31": "3.1 Erhebung von technisch notwendigen Daten und Logfiles",
    "text-15": "Wenn Sie unsere Webseiten rein informatorisch besuchen, sich also nicht registrieren oder auf sonstige Weise Daten übermitteln (z.B. über ein Kontaktformular) werden von uns die Daten erfasst, die ihr Browser an uns übermittelt. Im Einzelnen sind dies die folgenden Daten:",
    "text-16": "IP-Adresse",
    "text-17": "Datum und Uhrzeit der Anfrage",
    "text-18": "Zeitzonendifferenz zur Greenwich Mean Time (GMT)",
    "text-19": "Inhalt der Anforderung (konkrete Seite)",
    "text-20": "Jeweils übertragene Datenmenge",
    "text-21": "Webseite, von der aus Sie uns besuchen (Referrer URL)",
    "text-22": "Webseite, die Sie besuchen",
    "text-23": "Browsertyp und verwendete Version",
    "text-24": "Betriebssystem und dessen Oberfläche",
    "text-25": "Sprache und Version der Browsersoftware",
    "text-26": "Die Erhebung und Verarbeitung dieser Daten erfolgen, um Ihnen die Webseite anzeigen zu können, die Stabilität zu gewährleisten und zu verbessern, sowie aus Sicherheitsgründen. Die Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. f) DSGVO. Die Daten werden gelöscht, sobald sie für die zuvor genannten Zwecke nicht mehr erforderlich sind. Im Falle der Speicherung einer IP-Adresse erfolgt eine Löschung bzw. Anonymisierung nach spätestens 30 Tagen. Die Erhebung dieser Daten sowie die Speicherung der Daten in Logfiles ist für den Betrieb der Webseite zwingend erforderlich. Die Speicherdauer der Logfiles beträgt 90 Tage. Eine Widerspruchsmöglichkeit des Nutzers besteht daher nicht.",
    "text-27": "Sofern Sie Angebote auf unseren Webseiten nutzen, die eine Registrierung voraussetzen, werden weitere Daten gespeichert. Hinweise zur Registrierung finden Sie in den Nutzungsbedingungen im Absatz „Registrierung“.",
    "title-32": "3.2 Verwendung von Cookies und weiteren Technologien",
    "text-29": "Darüber hinaus werden bei der Nutzung unserer Webseite Cookies und weitere Technologien verwendet. Cookies sind kleine Textdateien, die durch uns oder durch eine andere Stelle (genauere Informationen finden Sie in der Beschreibung unserer Analyseverfahren im Folgenden) auf Ihrem Rechner gespeichert werden und durch welche der jeweils das Cookie setzenden Stelle bestimmte Informationen zufließen. Cookies werden dabei immer browserbezogen zugeordnet. Durch die Verwendung der Cookies ist es nicht möglich, Programme auszuführen oder Viren auf Ihren PC zu übertragen.",
    "text-30": "Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und beispielsweise die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Dies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Webseite nutzen können. Ebenso steht es Ihnen frei, jederzeit sämtlich Cookies über die Einstellungen Ihres Browsers zu löschen. Neben Cookies kommen im Einzelfall auch weitere Technologien (z. B. Pixel) zum Einsatz, um entsprechende Informationen zu erheben.",
    "title-321": "3.2.1 Verwendung von unbedingt erforderlichen Cookies und Technologien",
    "text-32": "Wir verwenden Cookies und Technologien, um Ihnen folgende Funktionen und Services anbieten zu können:",
    "text-3211": "Consent Management Platform",
    "text-3212": "Tag Management System",
    "text-3213": "Technisch erforderliche Webseiten-Cookies",
    "text-3214": "Fraud Protection",
    "text-3215": "Website Testing und Optimierung",
    "text-3216": "Basis Webanalyse",
    "text-321-p3": "Google Tag Manager",
    "text-321-p4": "Diese Website verwendet den Google Tag Manager („GTM“) als Tag-Management-System. Durch diesen Dienst können Tracking-Codes und Code-Fragmente („Tags) über eine Oberfläche verwaltet werden. Die Tags z. B. für Google Analytics werden über den GTM lediglich bereitgestellt, konfiguriert und gepflegt. Die Datensammlung und -verarbeitung selbst erfolgt hingegen über die hierfür einschlägigen Dienste. Demnach greift der GTM nicht auf die generierten Daten zu. Es werden keine Cookies eingesetzt und es werden keine personenbezogenen Daten erfasst.",
    "text-321-p5": "Application Insights",
    "text-321-p6": "Wir nutzen Application Insights, einen Webanalysedienst der Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399 USA („Microsoft“), um die Nutzung unserer Websites zu statistischen Zwecken auszuwerten sowie um Reports über Ihre Websiteaktivitäten zusammenzustellen. Zudem werden Auswertungen zum Zweck der Fehlerbehebung und Optimierung der Webseite und der Anwendung erstellt. Application Insights basiert auf der Datenbankanwendung „Azure“. Dabei wird Ihre IP-Adresse auf den Wert 0 gesetzt und dadurch anonymisiert. Die durch das Cookie erzeugten Informationen über Ihre Nutzung unserer Websites werden in der Regel an einen Server von Microsoft in den USA übertragen und dort gespeichert. Microsoft verarbeitet diese Informationen in unserem Auftrag. Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f) DSGVO.",
    "text-321-p7": "Weitergehende Informationen von Microsoft zum Thema Datenschutz und Azure finden Sie unter",
    "text-321-p8": "Google Analytics",
    "text-321-p9": "Wir setzen auf unseren Webseiten Google Analytics („GA“) ein, einen Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (\"Google\"), der es uns ermöglicht, die Nutzung unserer Webseiten zu statistischen Zwecken zu analysieren und ausschließlich Reichweitenmessung ohne Profilbildung zu betreiben. Das Cookie erzeugt Informationen über Ihre Nutzung unserer Webseiten, die wiederum über die Software Google Data Studio grafisch aufbereitet und visualisiert werden können. Google Data Studio greift lediglich auf bereits generierte Daten aus GA zurück. Eine rudimentäre und rein auf Ihre einzelne Webseiten-Sitzung bezogene Verarbeitung von Cookies für statistische Zwecke erfolgt nach Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse ergibt sich aus den vorgenannten Zwecken. Die Laufzeit des Cookies, sprich wie lange die gesammelten Informationen gespeichert werden, beträgt infolgedessen 30 Minuten, nachdem Sie inaktiv geworden sind. Ihre Sitzung ist somit beendet und es wird demnach gewährleistet, dass Sie nicht über mehrere Sitzungen hinweg wiedererkannt werden.",
    "text-321-p10": "Unsere Webseiten verwenden Google Analytics ausschließlich mit der Erweiterung \"_anonymizeIp()\", die eine Kürzung und Verschlüsselung Ihrer IP-Adresse innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum bewirkt. Somit ist ein direkter Personenbezug ausgeschlossen. Die erhobenen Informationen einschließlich der gekürzten und verschlüsselten IP-Adresse werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.",
    "text-321-p11": "Neben den allgemeinen Widerspruchsmöglichkeiten können Sie die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:",
    "text-321-p12": "Google reCaptcha",
    "text-321-p13": "Zu Zwecken der Anmelde- und Kontosicherheit nutzen wir auf unserer Webseite „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“). Google reCAPTCHA ist ein Dienst der Firma Google LLC (Mountain View, Kalifornien, Vereinigte Staaten). Für den europäischen Raum wird der Dienst durch das Unternehmen Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) angeboten.",
    "text-321-p14": "reCAPTCHA unterstützt uns durch die Überprüfung der Dateneingaben auf unseren Websites (z.B. in dem Anmeldevorgang) und unterscheidet, ob die Eingabe durch einen Menschen oder missbräuchlich durch automatisierte, maschinelle Verarbeitung erfolgt. Zu diesem Zweck analysiert reCAPTCHA das Verhalten des Websitebesuchers und wertet verschiedene Informationen aus. Die Abfrage schließt den Versand Ihrer IP-Adresse und ggf. Browserdaten wie Ihren User-Agent an den Servern von Google Ireland Limited ein. Ihre IP-Adresse wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die vollständige IP-Adresse an Google Servern in den USA übertragen und dort gekürzt. Außerdem werden für die Analyse der Anfragen die Verweildauer des Websitebesuchers auf der Webseite und die Mausbewegungen des Nutzers aufgezeichnet.",
    "text-321-p15": "Die reCAPTCHA-Analysen beginnen automatisch beim Betreten der Webseite durch den Besucher und laufen vollständig im Hintergrund. Die benötigten Daten für die Analyse werden ausschließlich zu den oben genannten Zwecken und zum Schutz Ihrer Anfragen per Internetformular verarbeitet. Auf unserer Seite erfolgt aus der Nutzung von reCAPTCHA keine Speicherung von personenbezogenen Daten. Generell gilt die Löschung oder Sperrung von personenbezogenen Daten der betroffenen Personen, sobald der Zweck der Speicherung entfällt. Die Speicherdauer der verarbeiteten Daten wird von Google Ireland Limited bestimmt und ist nicht durch uns beeinflussbar.",
    "text-321-p16": "Die Nutzung des Dienstes und die Datenverarbeitung erfolgen auf Grundlage unseres berechtigten Interesses, d.h. zum Schutz vor automatisierten Eingaben (Angriffen) und zur Sicherheit unserer Webseite gemäß Art. 6 Abs. 1 lit. f. DSGVO. Weitere Informationen und Hinweise zu den Datenschutzrichtlinien von Google reCAPTCHA finden Sie in der Datenschutzerklärung von Google {0} sowie auf der folgenden Seite {1}. Zur Absicherung des möglichen Drittenstaatentransfers an die Google LLC wurden Standardvertragsvertragsklauseln (Controller – Controller) abgeschlossen.",
    "title-322": "3.2.2 Verwendung von Third-Party-Cookies und weiterer Technologien Dritter",
    "text-322-p1": "Neben unseren eigenen Cookies verwenden wir auf unserer Webseite auch Third Party Cookies und weitere Technologien Dritter. Third Party Cookies sind solche, welche nicht von uns, sondern von Drittanbietern auf Ihrem Rechner gespeichert werden. Nähere Informationen zu Umfang und Zweck der Datenverarbeitung, der jeweiligen Rechtsgrundlage, der Speicherdauer sowie den Widerrufs-, Widerspruchs- und Beseitigungsmöglichkeiten der Third Party Cookies und der weiteren Technologien finden Sie unten bei der Erläuterung der einzelnen von uns genutzten Verfahren.",
    "text-322-p2-1": "Sie unten bei der Erläuterung der einzelnen von uns genutzten Verfahren. Sie können Ihre",
    "text-322-p2-a": "Einstellungen zu Cookies und Privatsphäre",
    "text-322-p2-2": "jederzeit in unseren Datenschutzinformationen online abrufen und Ihre getroffene Entscheidung ändern bzw. Ihre Einwilligung nachträglich erteilen oder widerrufen.",
    "text-34": "Rechtsgrundlage für diese Datenverarbeitung ist § 15 Abs.2 TTDSG i.V.m.  Art. 6 Abs. 1 lit. b) bzw. lit. f) DSGVO. Die Verarbeitung dient dazu, Ihnen die Nutzung unserer Webseite zu erleichtern und Ihnen unsere Dienste wie gewünscht anbieten zu können. Einige Funktionen unserer Webseite funktionieren auch nicht ohne den Einsatz dieser Cookies und könnten daher nicht angeboten werden. Unser berechtigtes Interesse an der Verarbeitung der Cookies ergibt sich aus den vorgenannten Zwecken. Die Cookies werden nach Beendigung der Session (z.B. Ausloggen oder Schließen des Browsers) oder nach dem Ablauf einer vorgegebenen Dauer gelöscht.",
    "title-33": "3.3 Verwendung von zustimmungspflichtigen Cookies und Technologien aufgrund einer Einwilligung",
    "text-330-p1-1": "Wir setzen auf unseren Webseiten die in diesem Abschnitt aufgeführten Dienste auf Grundlage Ihrer Einwilligung ein, die Sie uns über das Cookie-Banner gemäß § 25 Abs. 1 TTDSG i.V.m. Art. 6 Abs. 1 lit. a) DSGVO erteilt haben. Für diese Dienste gilt, dass Sie Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen bzw. nachträglich wieder erteilen können, indem Sie Ihre",
    "text-330-p1-a": "Einstellungen zu Cookies und Privatsphäre",
    "text-330-p1-2": "über unsere Datenschutzinformationen online abrufen und entsprechend konfigurieren. Alternativ können Sie die Speicherung von Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Bitte beachten Sie dabei, dass die vorgenommenen Browsereinstellungen immer nur für den jeweils verwendeten Browser wirken. Weitere Detailinformationen entnehmen Sie bitte den nachfolgenden Beschreibungen.",
    "title-331": "3.3.1 Google Analytics",
    "text-331-p1": "Wir setzen auf unseren Webseiten Google Analytics („GA“) ein, einen Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (\"Google\"). GA verwendet grundsätzlich \"Cookies\", um Informationen über die Nutzung unserer Webseiten zu erhalten und zu analysieren. Zudem erheben wir im Rahmen der sog. Werbefunktionen auch Daten für Marketingzwecke und für die Auslieferung von individuell abgestimmten Werbebotschaften. Diese Funktionen umfassen z. B. die Bildung von Nutzergruppen mit gleichen Interessen und Verhalten sowie die Erstellung von Remarketing-Listen auf Basis von gesammelten Interessens-, Verhaltens- und demografischen Daten. So werden Sie z. B. auf Basis Ihrer Interessen mit für Sie relevanten Inhalten angesprochen. Eine Verarbeitung Ihrer Daten erfolgt nur, wenn Sie im Rahmen des Cookie-Banners auf unseren Webseiten aktiv dem Bereich \"Marketing- und Personalisierung\" zugestimmt haben. Rechtsgrundlage für die Verarbeitung der Daten ist in diesen Fällen Ihre Einwilligung nach Art. 6 Abs. 1 lit. a), Art. 49 Abs. 1 lit. a) DSGVO. In unserem Auftrag wird Google die erhobenen Informationen verarbeiten, um die Nutzung unserer Website durch Sie auszuwerten und um Reportings über die Websiteaktivitäten zusammenzustellen. Die Laufzeit der Cookies, sprich wie lange die gesammelten Informationen gespeichert werden, beträgt 12 Monate. Unsere Webseiten verwenden GA ausschließlich mit der Erweiterung \"_anonymizeIp()\", die eine Kürzung und Verschlüsselung Ihrer IP-Adresse innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum bewirkt. Somit ist ein direkter Personenbezug ausgeschlossen. Die erhobenen Informationen einschließlich der gekürzten und verschlüsselten IP-Adresse werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.",
    "text-331-p2": "Neben den allgemeinen Widerrufsmöglichkeiten können Sie die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google auch verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:",
    "text-331-p3-1": "Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer Webseiten vollumfänglich nutzen können. Alternativ zum Browser-Plugin oder innerhalb von Browsern auf mobilen Geräten klicken Sie bitte auf den folgenden Link, um ein Opt-Out-Cookie zu setzen, der die Erfassung durch Google Analytics innerhalb dieser Website zukünftig verhindert (JavaScript muss aktiviert sein):",
    "text-331-p3-a": "Google Analytics deaktivieren",
    "text-331-p4": "Bitte beachten Sie, dass dieses Opt-Out-Cookie nur in diesem Browser und nur für diese Domain funktioniert. Löschen Sie Ihre Cookies in diesem Browser, müssen Sie den Link erneut klicken.",
    "text-331-p5": "Allgemeine Informationen zum Datenschutz bei Google finden Sie unter",
    "title-332": "3.3.2 MXO- Medallia Experience Orchestration",
    "text-332-p1": "Wir nutzen die MXO Medallia Experience Orchestration von Medallia, Inc., (6220 Stoneridge Mall Rd Floor 2, Pleasanton, CA 94588, USA). Hierbei wird ein Cookie auf Ihrem Endgerät gespeichert.",
    "text-332-p2": "Nutzung ohne Login im Kundenportal",
    "text-332-p3": "Sofern Sie nicht im Kundenportal/registrierten Bereich eingeloggt sind, wird Ihr Nutzerverhalten anonymisiert erfasst. Zu Ihrem Nutzerverhalten zählt, welche unserer Websites Sie besuchen, wie lange Sie dort verweilen, was Sie „anklicken“ oder in welche Felder Sie Daten eingeben (so genanntes Listening und Capturing). Dies dient der Verbesserung unseres Webangebots wie auch unserer Produkte und Dienstleistungen als solchen, da wir uns hierdurch Erkenntnisse darüber erhoffen, was unsere Kunden oder Interessenten/ Nutzer unserer Websites interessiert und welche Funktionen sie genau wie nutzen. Die IP-Adresse wird unmittelbar bei der Erhebung der Daten anonymisiert. Rechtsgrundlage für die Verarbeitung ist die von Ihnen beim Aufruf dieser Webseite im Rahmen unseres Cookie-Banners abgegebene Einwilligungserklärung (Art. 6 Abs. 1 lit. a) DSGVO). Weitere Informationen zur Datennutzung durch Medallia, zu Einstellungs- und Widerspruchsmöglichkeiten sowie zum Datenschutz können Sie den nachfolgenden Webseite von Medallia entnehmen:",
    "text-332-p4": "Nutzung während des Logins im Kundenportal",
    "text-332-p5": "Sofern Sie sich in den registrierten Bereich einloggen und Ihre Einwilligung hierzu erteilt haben, werden die mittels des MXO-Cookies erhobenen Daten zum Nutzerverhalten personenbezogen verarbeitet, indem sie mit Ihrer Identität und Ihren Vertragsdaten verknüpft werden. Die Erstellung Ihres persönlichen Kundenprofils dient dem Zweck, dass wir individuell auf Sie und für Ihre Interessen maßgeschneiderte Inhalte einblenden können, bei denen wir davon ausgehen, dass sie für Sie in Ihrer Situation besonders hilfreich oder interessant sein könnten. Medallia erhält dabei keine über die vorstehend genannten Daten hinausgehende personenbezogenen Daten. Rechtsgrundlage für die Datenverarbeitung ist die von Ihnen beim Aufruf dieser Webseite im Rahmen unseres Cookie-Banners abgegebene Einwilligungserklärung nach Art. 6 Abs. 1 lit. a) DSGVO. Das auf diese Weise erstellte Kundenprofil wird solange gespeichert, bis Sie entweder die Einwilligung widerrufen oder bis unsere Zwecke im Zusammenhang mit dem zwischen uns bestehenden Vertragsverhältnis erlöschen. Nähere Informationen entnehmen Sie bitte den Datenschutzhinweisen über zwischen uns bestehenden Vertragsverhältnis.",
    "title-34": "3.4 Nutzung der Registrierungsfunktion",
    "text-34-p1": "Wenn Sie den eingeloggten Bereich auf dieser Website (myenergykey.de) nutzen möchten, ist es erforderlich, dass Sie sich hierfür registrieren. Die myenergykey.de ist an den myEnergyKey, den zentralen Single Sign-On Dienst der EnBW AG, angeschlossen. Registrierung und Anmeldung erfolgen ausschließlich über den myEnergyKey Service. Nähere Informationen zur Datenverarbeitung im Rahmen der Nutzung von myEnergyKey können Sie den dortigen Datenschutzhinweisen entnehmen:",
    "text-34-p2": "Zum Zwecke der Identifizierung als Kunde im Rahmen des Angebots werden Vertragsdaten wie die Kunden- oder Vertragsnummer und die E-Mail-Adresse oder Mobilnummer aus dem Vertrag abgefragt. Die Identifizierung erfolgt anhand eines Doppel-Opt-Ins über die im Vertrag hinterlegten Kontaktdaten (E-Mail-Adresse oder Mobilnummer).",
    "text-34-p3": "Die Angabe der Daten ist zur Anlage des individuellen Zugangs erforderlich. Die im Rahmen des Registrierungsprozesses abgefragten Daten werden von uns zwecks Verwaltung Ihres Kundenportalzugangs gespeichert bis Sie Ihren Account löschen. Rechtsgrundlage der Datenverarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.",
    "title-35": "3.5 Telefonische Kontaktaufnahme",
    "text-35-p1": "Wenn Sie uns telefonisch kontaktieren, verwenden wir Ihre uns übermittelten Daten entsprechend dem jeweiligen Zweck, zu dem Sie uns kontaktiert haben. Rechtsgrundlage der Datenverarbeitung ist je nach Inhalt Ihrer Anfrage Art. 6 Abs. 1 lit. a), lit. b) oder f). Typische Beispiele für die zuvor genannten Rechtsgrundlagen sind:",
    "text-35-p2": "Die Daten, die Sie uns im Rahmen des Telefonats mitteilen, werden wir nach Erreichung des jeweiligen Zwecks löschen. Sollte unser Telefonat in Verbindung mit einem bestehenden Vertrag oder in Verbindung mit einer Vertragsanfrage stehen, beachten Sie bitte auch unsere Datenschutzhinweise in Bezug auf das jeweilige Produkt. Diese Datenschutzhinweise finden Sie",
    "text-35-li1": "Art. 6 Abs. 1 lit. a) DSGVO: Sie erteilen uns die Erlaubnis im Zuge Ihres Gesprächs mit unserem Kundenbetreuer das Gespräch zu Qualitätszwecken aufzuzeichnen",
    "text-35-li2": "Art. 6 Abs.1 lit. b) DSGVO: Mit Hilfe automatisierter Verfahren identifizieren Sie bereits im Zuge Ihres Anrufs und identifizieren mögliche Anliegen, um Sie an den für Sie zuständigen Kundenbetreuer weiterleiten zu können",
    "text-35-li3": "Art. 6 Abs. 1 lit. f) DSGVO: Unser berechtigtes Interesse zur Verarbeitung Ihrer personenbezogenen Daten kann sich auch aus dem Zweck der Steuerung unserer Hotline-Kapazitäten ergeben", 
    "text-35-p2-link": "hier",
    "title-36": "3.6 Verarbeitung Ihrer Daten aufgrund einer von Ihnen erteilten Einwilligung",
    "text-360-p1": "Eine Datenverarbeitung erfolgt zudem dann, wenn und soweit Sie in eine Datenverarbeitung nach Art. 6 Abs. 1 lit. a) DSGVO eingewilligt haben. Grundsätzlich ergeben sich die Zwecke der Datenverarbeitung aus der jeweiligen Einwilligung.",
    "text-360-p2-s1": "Sie haben z.B. die Möglichkeit uns eine Einwilligung für Marktforschungs- und Werbezwecke zu erteilen. Es besteht bei Abgabe der Einwilligung die Möglichkeit Einstellungen vorzunehmen und die Einwilligung zu individualisieren. Die Einstellungen können Sie auch jederzeit im Kundenportal Ihren Wünschen entsprechend anpassen.",
    "text-360-p2-s2": "Anpassungen sind in Bezug auf die gewünschten Kontaktkanäle, Bildung eines Interessenprofils, Anreicherung Ihrer Daten durch Daten bei Partnern, Themenbereiche und Kontaktaufnahme im Falle einer Änderung bei Partnern möglich. Wählen Sie eine Kategorie ab, erfolgt keine Datenverarbeitung zu dem jeweiligen Zweck oder über den gewählten Weg.",
    "text-360-p3": "Mit Erteilung der vollständigen Einwilligung erlauben Sie uns Ihnen personalisierte Informationen, Angebote sowie Umfragen per {0} zu übermitteln. Die personalisierten Informationen, Angebote und Umfragen beziehen sich auf unser vielfältiges Angebots- und Serviceprogramm und das unserer Partner.",
    "text-360-p3-bold": "E-Mail, SMS oder Telefon",
    "text-360-p4": "Dies umfasst die {0}:",
    "text-360-p4-bold": "Bereiche",
    "text-360-p5": "Energie",
    "text-360-p5-li1": "Strom",
    "text-360-p5-li2": "Gas",
    "text-360-p5-li3": "Wärmelieferung und -erzeugung",
    "text-360-p5-li4": "energienahe Dienstleistungen",
    "text-360-p5-li5": "Energiespeicher",
    "text-360-p5-li6": "Messstellenbetrieb",
    "text-360-p5-li7": "Beteiligungsmodelle (an Energieerzeugungsanlagen)",
    "text-360-p5-li8": "Solaranlagen",
    "text-360-p6": "Elektromobilität und Treibhausgasminderungsquote",
    "text-360-p7": "Telekommunikation",
    "text-360-p7-li1": "Festnetz-, Internet-Mobilfunktarife",
    "text-360-p7-li2": "Streaming (Musik, Film)",
    "text-360-p8": "Sonstiges",
    "text-360-p8-li1": "Elektroklein- und -großgeräte inklusive Zubehör",
    "text-360-p8-li2": "Versicherungen (Haushalt, Hardwaresicherheit)",
    "text-360-p8-li3": "Kundenvorteilsprogramme",
    "text-360-p9": "Bei unseren {0} handelt es sich um die",
    "text-360-p9-bold": "Partnern",
    "text-360-p9-name1": "EnBW mobility+ AG & Co. KG",
    "text-360-p9-address1": "Durlacher Allee 93, 76131 Karlsruhe (Anbieter für Elektromobilität)",
    "text-360-p9-name2": "Yello Strom GmbH",
    "text-360-p9-address2": "Siegburger Straße 229, 50679 Köln (Energieanbieter)",
    "text-360-p9-name3": "SENEC GmbH",
    "text-360-p9-address3": "Wittenberger Straße 15, 04129 Leipzig (Anbieter für Solaranlagen und Speicher)",
    "text-360-p9-name4": "DZ-4 GmbH",
    "text-360-p9-address4": "Kühnehöfe 3, 22761 Hamburg (Anbieter für Solaranlagen)",
    "text-360-p10": "Bei Erhalt von Angeboten, Informationen oder Umfragen per E-Mail verarbeiten wir Ihre Daten, indem wir ihr Öffnungs- und Klickverhalten auswerten, um unsere Angebote stetig zu verbessern.",
    "text-360-p11": "Aufgrund der Einwilligung verarbeiten wir Ihre Daten zudem, indem wir Ihre Online-Daten mit den bei uns bereits gespeicherten Daten zusammenführen. Hierdurch wird ein {0} gebildet, um noch passendere und individuellere Angebote für Sie bilden zu können. Dabei werden auch die Daten verarbeitet, die zu Ihrer Person bei unseren Partnern gespeichert sind.",
    "text-360-p11-bold": "Interessenprofil",
    "text-360-p12": "Das Interessenprofil wird wie folgt gebildet:",
    "text-360-p13": "Um zu ermitteln, welche unserer Informationen, Produkte und Angebote zu Ihnen passen und Ihren Interessen entsprechen, werten wir Ihre Daten mit verschiedenen Algorithmen und statistischen Verfahren aus.",
    "text-360-p14": "Dabei beziehen wir zum Beispiel Daten zur Marktentwicklung mit ein und berücksichtigen, welchen Bedarf an Produkten und Energiedienstleistungen typischerweise mit Ihnen vergleichbare Kundengruppen haben. Auch verwenden wir Ihre Daten zum Entwickeln neuer Verfahren und Analysemodelle. Soweit möglich, verarbeiten wir Ihre Daten pseudonymisiert bzw. anonymisiert.",
    "text-360-p15": "Wir verarbeiten persönliche Daten, die wir aus Geschäftsbeziehungen und aus der Zusammenarbeit mit Ihnen gewonnen haben. Dazu gehören:",
    "title-361": "3.6.1 Daten, die zu Ihrer Person gespeichert sind.",
    "text-361-p0": "Hierunter fallen:",
    "text-361-p1": "a. Kontakt- und vertragsbezogene Daten.",
    "text-361-p2": "Hierunter verstehen wir Stamm- und Kontaktdaten wie z. B. Name, Anschrift, Geburtsdatum, Geschlecht, Familienstand, Personenbeziehungen, Ihre E-Mailadresse oder Telefonnummer.",
    "text-361-p3": "b. Daten, die wir im Rahmen Ihrer Kommunikation mit uns erheben.",
    "text-361-p4-s1": "Das sind Daten, die wir im Rahmen unserer Beratungs- und Serviceangebote online oder im direkten Gespräch gewonnen haben, wie zum Beispiel Ihre mitgeteilten Ziele und Wünsche, Ihre Produktkenntnisse und Erfahrungen sowie Angaben zu Ihren persönlichen Verhältnissen.",
    "text-361-p4-s2": "Dazu gehören auch beispielsweise Informationen, wie Ihre Stromverbrauchsdaten, Hinweise auf einen bevorstehenden Umzug oder sonstige wohnliche Veränderungen zur Ermittlung eines optimalen Tarifs, Informationen zur Planung einer Solaranlage, Informationen zu bestehenden oder geplanten Anschaffungen wie z.B. eines E-Autos, einer Wallbox oder einer Wärmepumpe.",
    "text-361-p5": "c. Daten aus der Kundenbeziehung.",
    "text-361-p6": "Hierbei handelt es sich um Daten, die wir im Rahmen der Kundenbeziehung selbst erstellt oder aus anderen Quellen (z.B. aus öffentlich zugänglichen Verzeichnissen) zulässigerweise erhalten haben. Zu den Daten aus der Kundenbeziehung zählen z. B. Angaben aus Beratungsdokumentationen, wie etwa interne Protokolle, Kundenausdrucke sowie Daten aus der Konsumforschung und Informationen zur Wohnsituation oder -umfeld oder Informationen, die wir durch eigene Bewertungsverfahren gewinnen, um beispielsweise Ihre Zufriedenheit einzuschätzen.",
    "title-362": "3.6.2. Daten aus Ihrer Onlinenutzung.",
    "text-362": "Das sind Daten, die wir bei Ihrer Nutzung unseres Online-Angebots (Webseiten, Apps oder Portale) verarbeiten. Hierzu zählen z. B. Informationen über den von Ihnen gewählten Zugangsweg/ Kommunikationskanal (wie etwa IP-Adresse oder die Art des Empfangsgeräts), Datum und Uhrzeit der Nutzung, Informationen zu Ihrer Servicehistorie sowie Informationen zu den von Ihnen aufgerufenen und genutzten Online-Angeboten.",
    "title-363": "3.6.3 Daten unserer Partner.",
    "text-363-p1": "Dazu zählen Daten, die uns zulässigerweise von unseren Partnern übermittelt worden sind. Dazu können z. B. Informationen zu Ihren dortigen Verträgen, aber auch Angaben zu Beratungswünschen sowie zu Ihren persönlichen Verhältnissen gehören.",
    "text-363-p2": "Das Interessenprofil nutzen wir, um Sie individuell zu beraten. Sobald sich die Liste der Partner ändert, verarbeiten wir Ihre Daten, indem wir Sie über die Änderung per E-Mail, Telefon oder SMS informieren und um Ihre Erlaubnis bitten diesen Partner in die Einwilligung mit einbeziehen zu dürfen.",
    "title-4": "4. An welche Kategorien von Empfängern werden meine Daten übermittelt?",
    "text-48": "Wir behandeln Ihre Daten vertraulich. Innerhalb der EnBW Energie-Baden Württemberg AG erhalten nur die Abteilungen und Mitarbeiter Zugriff auf Ihre Daten, die dies zur Erfüllung der oben genannten Zwecke benötigen.",
    "text-49": "Personenbezogene Daten werden von uns an Dritte nur übermittelt, wenn dies für die vorgenannten Zwecke erforderlich und gesetzlich erlaubt ist oder Sie zuvor eingewilligt haben.",
    "text-50": "Neben den oben bereits konkret benannten Empfänger bedienen wir uns zur Erfüllung unserer Verpflichtungen der Hilfe weiterer Dienstleister (Auftragsverarbeiter). Folgende Empfängerkategorien können Daten erhalten:",
    "text-501": "IT-Dienstleister",
    "text-502": "Umfragedienstleister",
    "text-503": "Call-Center",
    "text-504": "Analyse-Spezialisten",
    "text-505": "Behörden",
    "text-506": "gesetzliche Betreuer und Personen, für die eine Vollmacht besteht",
    "text-51": "Eine Übermittlung personenbezogener Daten an andere Konzerngesellschaften erfolgt ebenfalls nur dann, wenn hierfür eine Rechtsgrundlage besteht und dies für einen der oben genannten Zwecke erforderlich ist.",
    "title-5": "5. Werden meine Daten auch an Empfänger in Staaten außerhalb des Europäischen Wirtschaftsraumes übermittelt? Wie wird ein angemessenes Datenschutzniveau sichergestellt?",
    "text-50-p1": "Wir übermitteln Ihre Daten auch an Dienstleister und Datenempfänger, die sich in Drittländern außerhalb der EU bzw. des EWR befinden. Die Einhaltung eines angemessenen Datenschutzniveaus wird auf Grundlage von Angemessenheitsbeschlüssen nach Art. 45 DSGVO bzw. durch andere geeignete Garantien nach Art. 46 ff. DSGVO sichergestellt. Bitte beachten Sie, dass im Falle einer Datenübermittlung in Drittländer dennoch das grundsätzliche Risiko bestehen kann, dass kein dem europäischen Recht entsprechendes angemessenes Datenschutzniveau vorliegt und Betroffenenrechte ggf. nicht vollumfänglich durchgesetzt werden können. Im Falle von Datenübermittlungen auf Grundlage von Art. 46 ff. DSGVO können Sie bei uns eine Kopie der einschlägigen Garantien über die oben genannten Kontaktdaten anfragen. Weitere Informationen zum internationalen Datentransfer finden Sie auch auf den Seiten der",
    "text-50-p1-link": "EU-Kommission",
    "text-50-p2": "Insbesondere für Datenübermittlungen im Wege von Administrationszugriffen ist ein Zugriff aus Drittländern möglich, da oftmals die Betriebsfähigkeit der Systeme nach dem Follow-the-Sun Prinzip sichergestellt wird. Ein Datenzugriff erfolgt in diesen Fällen ebenfalls nur, wenn die Einhaltung eines angemessenen Datenschutzniveaus sichergestellt ist.",
    "title-6": "6. Was muss ich bei der Nutzung von Links aus Datenschutzsicht beachten?",
    "text-60-p1": "Unsere Webseite kann Links zu anderen Anbietern enthalten, auf die sich unsere Datenschutzbestimmungen nicht erstrecken.",
    "title-7": "7. Wie steht es um die Sicherheit meiner Daten?",
    "text-70-p1": "Die EnBW setzt technische und organisatorische Sicherheitsmaßnahmen nach dem derzeitigen Stand der Technik ein, um Ihre uns zur Verfügung gestellten Daten vor zufälliger oder vorsätzlicher Manipulation, Verlust, Zerstörung oder dem Zugriff unberechtigter Personen zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.",
    "title-8": "8. Was gilt für Daten von Kindern?",
    "text-80-p1": "Wir ermuntern Erziehungsberechtigte nachdrücklich, die Online-Aktivitäten ihrer Kinder zu begleiten. Kinder sollten ohne Zustimmung der Eltern oder Erziehungsberechtigten keine personenbezogenen Daten an uns übermitteln. Wir fordern wissentlich keine personenbezogenen Daten von Kindern an und verarbeiten diese nicht wissentlich.",
    "title-9": "9. Welche Rechte habe ich in Bezug auf meine Daten?",
    "text-90-p1": "In Bezug auf die Verarbeitung ihrer personenbezogenen Daten, haben Sie gemäß Art. 15 DSGVO das Recht, Auskunft über die durch uns zu Ihrer Person verarbeiteten Daten zu verlangen. Des Weiteren stehen Ihnen die Rechte zu, Daten gemäß Art. 16 DSGVO berichtigen oder gemäß Art. 17 DSGVO löschen zu lassen, sowie die Verarbeitung gemäß Art. 18 DSGVO einzuschränken. Des Weiteren haben Sie gemäß Art. 20 DSGVO das Recht, die Herausgabe der durch Sie bereitgestellten personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu verlangen. Hinsichtlich des Auskunftsrechts gelten die Einschränkungen des § 34 BDSG und bezüglich des Löschungsrechts die Ausnahmen des § 35 BDSG.",
    "text-90-p2": "Widerspruchsrecht Art. 21 DSGVO",
    "text-90-p3": "Sofern wir Ihre Daten aufgrund berechtigter Interessen (Art. 6 Abs. 1 lit. f) DSGVO) oder zur Wahrnehmung einer öffentlichen Aufgabe (Art. 6 Abs. 1 lit. e) DSGVO) verarbeiten und wenn sich aus Ihrer besonderen Situation Gründe gegen diese Verarbeitung ergeben, haben Sie gemäß Art. 21 Abs. 1 DSGVO das Recht auf Widerspruch gegen diese Verarbeitung. Im Falle eines Widerspruchs verarbeiten wir Ihre Daten nicht mehr zu diesen Zwecken, es sei denn wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.",
    "text-90-p4": "Ein Recht auf Widerspruch steht Ihnen – ohne Einschränkung - gemäß Art. 21 Abs. 2 und 3 DSGVO gegen jede Art der Verarbeitung zu Zwecken der Direktwerbung zu.",
    "text-90-p5": "Ihren Widerspruch können Sie jederzeit formfrei an uns richten.",
    "text-90-contact-details": "Zur bestmöglichen Bearbeitung bitten wir Sie, die folgenden Kontaktdaten zu nutzen:",
    "text-90-name": "EnBW Energie Baden-Württemberg AG",
    "text-90-address1": "Durlacher Allee 93",
    "text-90-address2": "76131 Karlsruhe",
    "text-90-central-phone": "Telefonzentrale: +49 721 63-00",
    "text-90-customer-service-number": "Kundenservicenummer: +49 721 72586-001",
    "text-90-email": "E-Mail:",
    "text-90-p6": "Bitte beachten Sie, dass Sie Ihren Widerspruch gegen die Nutzung von Tracking Verfahren auf unserer Webseite nur selbst umsetzen können. Es ist technisch nicht möglich, dies zentral durchzuführen. Bei jedem Tracking Verfahren, bei dem Sie den Widerspruch selbst umsetzen müssen, haben wir Ihnen oben eine Erklärung beigefügt.",
    "title-10": "10. Kann ich erteilte Einwilligungen widerrufen?",
    "text-100-p1": "Sofern wir Ihre Daten auf Basis einer von Ihnen erteilten Einwilligung verarbeiten, haben Sie das Recht, Ihre Einwilligung jederzeit zu widerrufen. Ihre Daten werden dann nicht mehr zu den von der Einwilligung umfassten Zwecken verarbeitet. Bitte beachten Sie, dass die Rechtmäßigkeit der Datenverarbeitung, welche vor dem Widerruf erfolgt ist, durch den Widerruf nicht berührt wird. Wie Sie Ihren Widerruf im Einzelnen erklären können, entnehmen Sie bitte den vorangegangenen Informationen bzw. der Information in der jeweiligen Einwilligung. Richten Sie Ihren Widerruf gerne an:",
    "text-100-p2": "Hier wird ihr Widerruf – sofern technisch möglich – direkt zentral umgesetzt oder Ihnen wird erläutert wie Sie selbst den Widerruf umsetzen können, da eine zentrale Umsetzung durch uns bei manchen technischen Verfahren nicht möglich ist.",
    "title-11": "11. Habe ich ein Beschwerderecht bei einer Aufsichtsbehörde?",
    "text-110-p1": "Sofern Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen geltendes Recht verstößt, können Sie sich gemäß Art. 77 DSGVO jederzeit mit einer Beschwerde an eine Datenschutzaufsichtsbehörde wenden. Dies gilt unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.",
    "title-12": "12. Muss ich die Daten bereitstellen oder ist die Bereitstellung für den Vertragsschluss erforderlich?",
    "text-120-p1": "Mit Ausnahme der technisch notwendigen Daten zur Anzeige unserer Webseite ist jegliche Datenbereitstellung durch Sie freiwillig. Sollte dies ausnahmsweise anders sein, so ist dies an der entsprechenden Stelle in dieser Erklärung explizit erwähnt.",
    "title-13": "13. Wird anhand meiner Daten eine automatisierte Entscheidungsfindung durchgeführt? Und wenn ja, wie wird das gemacht und welche Auswirkungen hat dies auf mich?",
    "text-130-p1": "Nein, eine automatisierte Entscheidungsfindung findet nicht statt.",
    "title-14": "14. Können diese Informationen geändert werden?",
    "text-140-p1": "Da unsere Datenverarbeitung Änderungen unterliegt, werden wir auch unsere Datenschutzinformationen von Zeit zu Zeit anpassen. Den jeweils aktuellen Stand dieser Datenschutzbestimmungen finden Sie an dieser Stelle.",
    "download-button": "Als PDF herunterladen"
  },

  "unavailable-page": {
    "headline": "Keine Antwort",
    "subhead": "Der Server Antwortet nicht. Bitte versuchen Sie es später noch einmal.",
    "back-button": "Erneut laden"
  },

  "identity-linking": {
    "eligibility-check-progress": {
      "text": "Ihr Anfrage wird geprüft. Bitte haben Sie einen Moment Geduld"
    },
    "letter-renewal-options": {
      "headline": "Wie möchten Sie den neuen Einladungslink bekommen?",
      "renew-via-email-action": "E-Mail (sofort)",
      "renew-via-letter-action": "Brief (3-4 Werktage)"
    },
    "email-renewal-form": {
      "headline": "Ihre E-Mail-Adresse"
    },
    "eligibility-check-result": {
      "account-already-exists": {
        "headline": "Konto existiert bereits",
        "text": "Die Einladung wurde bereits verwendet und ein Konto wurde erstellt. Bitte melden Sie sich an.",
        "action": "Anmelden"
      },
      "process-expired": {
        "headline": "Einladungslink abgelaufen",
        "text": "Es sieht so aus, als kämen Sie zu spät, um Ihr Konto zu aktivieren. Aber keine Sorge, Sie können eine neue Einladung anfordern.",
        "action": "Neue Einladung anfordern"
      }
    },
    "restart-process": {
      "success": {
        "headline": "Neue Einladung ist unterwegs",
        "email-flow": {
          "text": "Um Ihr myEnergyKey-Konto zu erstellen, klicken Sie bitte auf den Link in der E-Mail, die wir Ihnen an {email} gesendet haben.",
          "actions": {
            "resend": "Neue Einladung anfordern",
            "edit": "E-Mai-Adresse ändern"
          }
        },
        "letter-flow": {
          "text": "Ein Brief mit einem neuen Einladungslink wird in wenigen Tagen bei Ihnen eintreffen."
        }
      }
    },
    "notifications": {
      "failure": {
        "title": "Anfrage fehlgeschlagen",
        "text": "Versuchen Sie es später nocheinmal"
      },
      "success": {
        "with-email-text": "E-Mail mit einer neuen Einladung wurde an Sie versendet."
      }
    },
    "zip-code-verification-dialog": {
      "title": "Identität bestätigen",
      "inputPlaceholder": "Ihre Postleitzahl",
      "actionHelpText": "Damit wir sicher sein können, dass Sie es sind, benötigen wir zum Abgleich Ihre Postleitzahl.",
      "serviceContactAction": "Service kontaktieren",
      "validation-messages": {
        "length-error": "Geben Sie eine gültige PLZ an.",
        "invalid-chars": "Die PLZ enthält ungültige Zeichen.",
        "failed-attempt": "Falsche Postleitzahl. {numberOfRemainingAttempts} Versuche übrig.",
        "failed-attempt-last": "Falsche Postleitzahl. Zugang gesperrt."
      }
    },
    "process-success": {
      "headline": "Identität bestätigt",
      "text": "Ihre Identität wurde erfolgreich bestätigt. Sie können nun mit der Erstellung Ihres Kontos fortfahren.",
      "action": "myEnergyKey-Konto erstellen"
    },
    "service-contact-dialog": {
      "title": "Servicekontakt",
      "postal-section": {
        "title": "Postalisch"
      },
      "phone-section": {
        "title": "Telefonisch"
      },
      "email-section": {
        "title": "Via E-Mail"
      }
    }
  }
}
