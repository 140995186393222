import type { ComputedRef } from 'vue'

import useUserStore from '~/stores/user'
import { ContractData  } from '~/stores/user/types'
import useUserPersonalData, { INITIAL_CUSTOMER_PROFILE } from '~/composables/useUserPersonalData'
import { Cache, UserPersonalDataComposable } from '~/composables/useUserPersonalData/types'

export interface UserContractDataComposable extends UserPersonalDataComposable<ContractData> {
  contractData: ComputedRef<ContractData>
  hasContractsWithPaperCommunication: ComputedRef<boolean>
  contractsWithPaperCommunication: ComputedRef<{contractId: string, isPaperlessCommunication: boolean}[]>
}

export const useUserContractData = (): UserContractDataComposable => {
  const userStore = useUserStore()

  async function fetch(cacheState?: Cache): Promise<ContractData> {
    const data = await useUserPersonalData(cacheState)

    return data.contractData
  }

  const isLoading = computed(() => userStore.isCustomerProfileDataLoading)

  const contractData = computed<ContractData>(() => userStore.customerProfileData?.contractData ?? INITIAL_CUSTOMER_PROFILE.contractData)

  /**
   * Returns all customer contracts with paper communication
   *
   * @returns {ComputedRef<{contractId: string, isPaperlessCommunication: boolean}[]>}
   */
  const contractsWithPaperCommunication = computed(() => contractData.value.contracts.filter(contract => !contract.isPaperlessCommunication))

  /**
   * Indicates that customer has contract with paper communication
   *
   * @returns {ComputedRef<boolean>}
   */
  const hasContractsWithPaperCommunication = computed(() => contractData.value.contracts.some(contract => !contract.isPaperlessCommunication))

  return {
    fetch,
    isLoading,

    contractData,
    hasContractsWithPaperCommunication,
    contractsWithPaperCommunication
  }
}
