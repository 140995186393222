import { defineStore } from 'pinia'

import { StoreKeys } from '~/stores/types'
import State, { RegistrationData, CustomerProfileData, ContractDataItem, TenantMode } from './types'
import { TenantId } from '~/composables/domain/useTenantConfig/types'
import { GLOBAL_EVENTS, GLOBAL_EVENTS_GROUP, useGlobalEvents } from '~/composables/utils/useGlobalEvents'

const eventBus = useGlobalEvents(GLOBAL_EVENTS_GROUP.USER)

interface Actions {
  setRegistrationData: (data: RegistrationData) => void
  setTenant: (tenantId: TenantId) => void

  setCustomerProfileData: (data: CustomerProfileData | undefined) => void
  setIsCustomerProfileDataLoading: (data: boolean) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useUserStore = defineStore<StoreKeys, State, any, Actions>(StoreKeys.PROFILE, {
  state: () => ({
    tenantMode: TenantMode.MEK,
    selectedTenantId: undefined,
    registrationData: undefined,

    _customerProfileData: undefined,
    isCustomerProfileDataLoading: true
  }),

  getters: {
    customerProfileData(state: State) {
      return state._customerProfileData
    },
    tenantId(state: State) {
      return state.selectedTenantId || state.registrationData?.lastUsedApplication?.tenantId
    }
  },

  actions: {
    setRegistrationData(data: RegistrationData) {
      this.registrationData = data
    },
    setTenant(tenantId: TenantId) {
      this.selectedTenantId = tenantId
      this.tenantMode = TenantMode.SELECTED

      eventBus.emit(GLOBAL_EVENTS.USER_TENANT_DEFINED)
    },

    setCustomerProfileData(data: CustomerProfileData | undefined) {
      this._customerProfileData = data
    },
    setIsCustomerProfileDataLoading(data: boolean) {
      this.isCustomerProfileDataLoading = data
    }
  }
})

export function getEnbwContractManagementLink(contractData: ContractDataItem): string {
  return `https://meine.enbw.com/vertrag/${contractData.contractId}`
}
export function getEnbwContractCancellationLink(): string {
  return 'https://www.enbw.com/kuendigen'
}
export function getYelloContractManagementLink(contractData: ContractDataItem): string {
  return `https://meine.enbw.com/vertrag/${contractData.contractId}`
}
export function getYelloContractCancellationLink(): string {
  return 'https://www.yello.de/kuendigen'
}

export default useUserStore
