export enum TenantId {
  Yello = '200',
  Enbw = '100',
  Senec = '500'
}

export interface LastUsedApplication {
  lastUsedTimestampUtc: string
  name: string
  tenantId: TenantId
}
